import {
    Slice,
    PayloadAction,
    createSlice,
} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from 'redux'
import { IBreadcrumbItem, ICommandBarItemProps, MessageBarType } from '@fluentui/react'
import { ReactElement } from 'react'

/**
 * Common middlewares, must have "getDefaultMiddleware"
 * @type {Middleware[]}
 */
const commonMiddleware = []

/**
 * Payload Init
 * @typedef {object} PayloadMessageBar
 * @property {boolean} isDisplayed Is message bar display ?
 * @property {MessageBarType=} type MessageBarType
 * @property {(string|object)=} message Message to show
 * @property {boolean=} isFixed message bar cannot be manually removed
 *
 * Payload Url
 * @typedef {string} PayloadUrl Current Url
 *
 * Payload Breadcrumb
 * @typedef {IBreadcrumbItem[]=} PayloadBreadcrumb Breadcrumb items
 *
 * Payload Command
 * @typedef {ICommandBarItemProps[]=} PayloadCommand Commanbar items
 *
 * Payload Modal
 * @typedef {object} PayloadModal
 * @property {boolean} show Is modal displayed
 * @property {string=} title Title
 * @property {string=} subTitle Subtitle
 * @property {Function=} callback Callback when clicking "Yes" button
 * @property {ReactElement=} content JSX content
 *
 * Payload Lang
 * @typedef {import('types/translations').LangsType=} PayloadLang Lang
 */

/**
 * Common State
 * @typedef {object} CommonState
 * @property {string} selectedKeyURL Current URL in application
 * @property {import('types/translations').LangsType} lang Current lang
 * @property {object} messageBar Messagebar
 * @property {boolean} messageBar.isDisplayed Is message bar displayed
 * @property {MessageBarType=} messageBar.type Messagebar type
 * @property {string=} messageBar.message Text
 * @property {boolean=} messageBar.isFixed isFixed
 * @property {IBreadcrumbItem[]} breadcrumb Breadcrumb items
 * @property {ICommandBarItemProps[] | object[]} command Command items
 * @property {object} modal Modal
 * @property {boolean} modal.show Is modal displayed
 * @property {string=} modal.title Title
 * @property {string=} modal.subTitle Subtitle
 * @property {Function=} modal.callback Callback when clicking "Yes" button
 * @property {ReactElement=} modal.content JSX content
 */
/**
 * Common Slice
 * @type {Slice<CommonState>}
 */
const commonSlice = createSlice({
    name: 'common',
    /** @type {CommonState} */
    initialState: {
        selectedKeyURL: '/',
        lang: (() => {
            const langs = (
                localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_language`)
                || window?.navigator?.languages
                || ['en']
            )?.toString()

            const lang = (() => {
                if (langs.includes('fr'))
                    return 'fr'
                if (langs.includes('nl'))
                    return 'nl'
                return 'en'
            })()

            document.documentElement.lang = lang

            return lang
        })(),
        messageBar: {
            isDisplayed: false,
            type: null,
            message: null,
            isFixed: false,
        },
        breadcrumb: [],
        command: [],
        modal: {
            show: false,
            title: '',
            subTitle: '',
            callback: () => null,
            content: null,
        },
    },
    reducers: {
        /**
         * Set MessageBar
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadMessageBar>} action action
         */
        setMessageBar: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.messageBar = action.payload
        },
        /**
         * Set Current Url
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadUrl>} action action
         */
        setUrl: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.selectedKeyURL = action.payload
        },
        /**
         * Set Breadcrumb
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadBreadcrumb>} action action
         */
        setBreadcrumb: (state, action) => {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            state.breadcrumb = action.payload
        },
        /**
         * Set CommandBar
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadCommand>} action action
         */
        setCommand: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.command = action.payload
        },
        /**
         * Set Modal
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadModal>} action action
         */
        setModal: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.modal = action.payload
        },
        /**
         * Set lang
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadLang>} action action
         */
        setLang: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.lang = action.payload
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_language`, action.payload)
            document.documentElement.lang = action.payload
        },
    },
})

const {
    setMessageBar, setUrl, setBreadcrumb, setCommand, setModal, setLang,
} = commonSlice.actions
const commonReducer = commonSlice.reducer

export {
    setMessageBar, setUrl, setBreadcrumb, setCommand, setModal, setLang, // Reducers, used to call actions
    commonReducer, // All reducers, used to create store
    commonMiddleware, // Middleware
}
