import React, {
    useState, useCallback, useRef,
} from 'react'
import {
    DefaultButton, DirectionalHint, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import Department from 'requests/objects/department'
import Status from 'types/status'
import Param from 'requests/objects/param'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/components/pages/companies/pivot-departments.module.scss'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import parseJson from 'helpers/methods/parseJson'
import DepartmentList from 'components/pages/company/department/department-list'
import DepartmentModal from 'components/pages/company/department/department-modal'
import ImportDataModal from 'components/modals/import-data-modal'
import HandleBlob from 'helpers/methods/blob'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'

/**
 * @typedef {object} DepartmentIndexes
 * @property {number?} clientId - client id for client pivot
 * @property {number?} companyId - company id for company pivot
 */

/**
 * @typedef {object} SearchParamsType
 * @property {string} code code
 * @property {number} countryId countryId
 * @property {number} operatingCenterId operatingCenterId
 * @property {number} pickupZoneId pickupZoneId
 * @property {number} deliveryZoneId deliveryZoneId
 */

/**
 * @type {SearchParamsType}
 */
const defaultSearchParams = {
    code: '',
    countryId: null,
    operatingCenterId: null,
    pickupZoneId: null,
    deliveryZoneId: null,
}

/**
 * Pivot departments
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {Param} props.param param
 * @param {number=} props.companyId companyId
 * @param {function(import('redux/slices/common').PayloadModal):void} props.setModal Set Modal
 * @returns {JSX.Element} Element
 */
export default function PivotDepartments({
    lang,
    companiesHandler,
    param,
    companyId,
    setModal,
}) {
    const { tObj, tKey } = useTranslate({ lang })
    const [status, setStatus] = useState(Status.IDLE)
    const [departments, setDepartments] = useState([])
    const [isDepartmentModalVisible, setIsDepartmentModalVisible] = useState(false)
    const [department, setDepartment] = useState(new Department())
    const [searchParams, setSearchParams] = useState(defaultSearchParams)
    const [isImportDataVisible, setIsImportDataVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Department>>} */
    const deleteDepartmentHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Department[]>>} */
    const searchdepartmentsHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Department[]>>} */
    const handlerDepartmentsImport = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const downloadTemplateHandler = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const downloadExampleHandler = useRef()

    const submitInput = useRef(null)
    const toolTipRef = useRef(null)

    const editDepartment = useCallback(
        /**
         * Edit department
         * @param {Department} departmentToEdit departmentToEdit
         */
        departmentToEdit => {
            setDepartment(departmentToEdit)
            setIsDepartmentModalVisible(true)
        }, [],
    )

    const deleteDepartment = useCallback(
        /**
         * Delete department
         * @param {Department} departmentToDelete departmentToDelete
         */
        departmentToDelete => {
            setModal({
                show: true,
                title: tKey('removeDepartment'),
                subTitle: tKey('sureRemoveDepartment'),
                callback: async () => {
                    try {
                        deleteDepartmentHandler.current = companiesHandler.removeDepartmentById(companyId, departmentToDelete.departmentId)
                        const dep = await deleteDepartmentHandler.current.fetch()
                        setDepartments(olds => olds.filter(x => x.departmentId !== dep.departmentId))
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }
                },
            })
        }, [companiesHandler, companyId, setModal, tKey],
    )

    const addDepartment = useCallback(() => {
        setDepartment(new Department({ companyId }))
        setIsDepartmentModalVisible(true)
    }, [companyId])

    const search = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            searchdepartmentsHandler.current?.cancel()
            searchdepartmentsHandler.current = companiesHandler.getAllDepartments(companyId, searchParams)
            const res = await searchdepartmentsHandler.current.fetch()
            setDepartments(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setDepartments([])
                    setStatus(Status.RESOLVED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [companiesHandler, companyId, searchParams])

    const onImport = useCallback(async file => {
        try {
            setStatus(Status.PENDING)
            store.dispatch(setMessageBar({ isDisplayed: false }))
            setErrorMessage(null)
            handlerDepartmentsImport.current = companiesHandler.importDepartments(file, companyId)
            const res = await handlerDepartmentsImport.current.fetch()
            setDepartments(res)
            setIsImportDataVisible(false)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            setErrorMessage(error)
        }
    }, [companiesHandler, companyId])

    /**
     * Download template
     */
    const onDownloadTemplate = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            downloadTemplateHandler.current = companiesHandler.downloadDepartmentsTemplate(companyId)
            const blob = await downloadTemplateHandler.current.fetch()
            HandleBlob.download(blob, 'departments-import-template.xlsx')
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            setErrorMessage(error)
        }
    }, [companiesHandler, companyId])

    /**
     * Download example
     */
    const onDownloadExample = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            downloadExampleHandler.current = companiesHandler.downloadDepartmentsExample(companyId)
            const blob = await downloadExampleHandler.current.fetch()
            HandleBlob.download(blob, 'departments-import-exemple.xlsx')
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            setErrorMessage(error)
        }
    }, [companiesHandler, companyId])

    return (
        <main className={styles['pivot-departments']}>
            <Card
                title={tKey('filters')}
                iconName="FilterSettings"
                headerComponent={(
                    <div
                        className={styles['action-filter-buttons']}
                    >
                        <div>
                            <DefaultButton
                                text={tKey('research')}
                                primary
                                disabled={status === Status.PENDING}
                                onClick={() => submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                            />
                        </div>
                        <div className={styles.separator} />
                        <div>
                            <TooltipHost
                                content={tKey('resetFilters')}
                                directionalHint={DirectionalHint.topAutoEdge}
                                delay={TooltipDelay.medium}
                                componentRef={toolTipRef}
                            >
                                <DefaultButton
                                    disabled={status === Status.PENDING}
                                    styles={{
                                        root: {
                                            minWidth: '40px',
                                            maxWidth: '40px',
                                        },
                                    }}
                                    iconProps={{ iconName: 'ClearFilter' }}
                                    onClick={() => setSearchParams(defaultSearchParams)}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                )}
            >
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-fifth">
                            <FilteredVirtualCombobox
                                label={tKey('country')}
                                options={param.countries.map(({ key, text }) => ({
                                    key,
                                    text: tObj(parseJson(text)),
                                }))}
                                selectedKey={searchParams.countryId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => setSearchParams(prev => ({ ...prev, countryId: option.key }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <FilteredVirtualCombobox
                                label={tKey('operatingCenter')}
                                options={param.operatingCenters.map(({ key, text }) => ({
                                    key,
                                    text: tObj(parseJson(text)),
                                }))}
                                selectedKey={searchParams.operatingCenterId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => setSearchParams(prev => ({ ...prev, operatingCenterId: option.key }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('code')}
                                disabled={status === Status.PENDING}
                                value={searchParams.code}
                                onChange={(ev, newVal) => setSearchParams(prev => ({ ...prev, code: newVal }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('pickupZone')}
                                value={searchParams.pickupZoneId?.toString() || ''}
                                type="number"
                                onChange={(_ev, newVal) => setSearchParams(prev => ({ ...prev, pickupZoneId: newVal?.length > 0 ? +newVal : undefined }))}
                                disabled={status === Status.PENDING}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('deliveryZone')}
                                value={searchParams.deliveryZoneId?.toString() || ''}
                                type="number"
                                onChange={(_ev, newVal) => setSearchParams(prev => ({ ...prev, deliveryZoneId: newVal?.length > 0 ? +newVal : undefined }))}
                                disabled={status === Status.PENDING}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={submitInput}
                        tabIndex={-1}
                    />
                </form>
            </Card>
            <br />
            <Card
                iconName="MapPin"
                title={tKey('departments')}
                headerComponent={(
                    <>
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                setIsImportDataVisible(true)
                            }}
                            text={tKey('integrateDepartments')}
                        />
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                addDepartment()
                            }}
                            text={tKey('add')}
                        />
                    </>
                )}
                hasDividerMargin={false}
            >
                <DepartmentList
                    editDepartment={editDepartment}
                    deleteDepartment={deleteDepartment}
                    items={departments}
                    lang={lang}
                    status={status}
                    countries={param.countries.map(({ key, text }) => ({
                        key,
                        text: tObj(parseJson(text)),
                    }))}
                />
            </Card>
            <DepartmentModal
                departmentData={department}
                isVisible={isDepartmentModalVisible}
                companiesHandler={companiesHandler}
                onChange={departmentReturned => {
                    if (departmentReturned)
                        if (!department.departmentId)
                            setDepartments([...departments, departmentReturned])
                        else
                            setDepartments(prev => prev.map(x => (x.departmentId === departmentReturned.departmentId ? departmentReturned : x)))

                    setIsDepartmentModalVisible(false)
                }}
                lang={lang}
                param={param}
            />
            <ImportDataModal
                lang={lang}
                isVisible={isImportDataVisible}
                setIsVisible={setIsImportDataVisible}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                onDownloadTemplate={onDownloadTemplate}
                onDownloadExample={onDownloadExample}
                onImport={onImport}
                status={status}
                title={tKey('integrateDepartments')}
            />
        </main>
    )
}
