import User, { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'
import EAuthStrategy from 'types/users/enums/authStrategy'

/**
 * UsersHandler
 * @augments {ApiHandler<User, ErrorUser>}
 */
export default class UsersHandler extends ApiHandler {
    constructor() {
        super({ type: User, errorType: ErrorUser, key: 'users' })
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.email email
     * @param {string} data.password password
     * @param {EAuthStrategy} data.strategy strategy
     * @param {string} data.googleToken googleToken
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    login({
        email,
        password,
        strategy,
        googleToken,
    }) {
        const request = this.initFetchRequest({
            url: ['login'],
            method: 'POST',
            data: {
                email,
                password,
                strategy,
                googleToken,
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.refreshToken refreshToken
     * @param {string} data.accessToken accessToken
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    logout({ refreshToken, accessToken }) {
        const request = this.initFetchRequest({
            url: ['logout'], method: 'POST', data: { refreshToken }, headers: { Authorization: `Bearer ${accessToken}` },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.error(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.refreshToken refreshToken
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    refresh({ refreshToken }) {
        const request = this.initFetchRequest({ url: ['refresh'], method: 'PATCH', data: { refreshToken } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get informations about current user
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    getMe() {
        const request = this.initFetchRequest({ url: ['me'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create new password
     * @param {object} data data
     * @param {string} data.plainPassword plainPassword
     * @param {string} data.plainPasswordTwo plainPasswordTwo
     * @param {string} data.signupKey signupKey
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    signup(data) {
        const request = this.initFetchRequest({ url: ['signup'], method: 'POST', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Reset password
     * @param {string} email email
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    reset(email) {
        const request = this.initFetchRequest({ url: ['reset-password'], method: 'POST', data: { email } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update user phone number
     * @param {object} props props
     * @param {string} props.phoneNumber phoneNumber
     * @param {string} props.firstname firstname
     * @param {string} props.lastname lastname
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    updateUserPhoneNumber(props) {
        const request = this.initFetchRequest({ url: ['phone-number'], method: 'PUT', data: props })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get informations about current user
     * @param {string} language language
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    patchMeLanguage(language) {
        const request = this.initFetchRequest({ url: ['me', 'language'], data: { language }, method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get informations about current user
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    switchCompany(companyId) {
        const request = this.initFetchRequest({ url: ['me', 'company'], data: { companyId: +companyId }, method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Switch client context
     * @param {number} clientId clientId
     * @returns {import('requests/apiHandler').RequestApi<User>} Request
     */
    switchClient(clientId) {
        const request = this.initFetchRequest({ url: ['me', 'client'], data: { clientId: +clientId }, method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
