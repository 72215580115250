/**
 * Flat object with nested properties
 * @param {object} obj Object
 * @param {object=} parent Parent object
 * @param {object=} res Final result
 * @returns {any} any
 */
export default function flattenObj(obj, parent, res = {}) {
    // eslint-disable-next-line
    for (const key in obj) {
        const propName = parent ? `${parent}.${key}` : key
        if (typeof obj[key] === 'object' && Object.prototype.toString.call(obj[key]) !== '[object Date]')
            flattenObj(obj[key], propName, res)
        else
            res[propName] = obj[key]
    }
    return res
}
