import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField, Text,
    Toggle, DefaultButton, Icon, Label, Pivot, PivotItem,
} from '@fluentui/react'
import history from 'helpers/history'
import Client, { ClientActivity, ErrorClient } from 'requests/objects/client'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import PreviewFileInput from 'components/pages/home-articles/[id]/previewFileInput'
import FileInput from 'components/inputs/fileInput'
import EFileFolder from 'types/files/enums/fileFolder'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import FileCategory from 'requests/objects/fileCategory'
import parseJson from 'helpers/methods/parseJson'
import { getUpdatedList } from 'helpers/methods/common'
import SupportContacts from 'components/pages/supportContacts'
import PivotSupports from 'components/pages/clients/pivot-supports'
import PivotOperatingCenters from 'components/pages/company/operating-center/pivot-operating-centers'
import PivotArticles from 'components/pages/clients/pivot-articles'
import ECompany from 'types/companies/enums/company'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import PhoneField from 'components/inputs/phoneField'
import PivotAddresses from 'components/pages/company/pivot-addresses'
import EProfile from 'types/users/enums/profile'
import EdeliveryDateType from 'types/deliveryDateType/enums/deliveryDateType'

const paramMinDays = [
    { key: 0, text: '0' },
    { key: 1, text: '1' },
    { key: 2, text: '2' },
    { key: 3, text: '3' },
    { key: 4, text: '4' },
    { key: 5, text: '5' },
    { key: 6, text: '6' },
]

/** @debug {import("app").AppProps} */

/**
 * @augments {PureComponent<import("app").AppProps>}
 */
export default class Idclients extends PureComponent {
    constructor(props) {
        super(props)
        const { match, me } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.clientId,
            /** @type {Client} Element find from API */
            item: new Client(),
            /** @type {Client} Initial element fond from API. Used to refresh */
            iniItem: new Client(),
            /** @type {ErrorClient} Errors */
            errorField: new ErrorClient(),
            /** @type {globalThis.File} tempLogo */
            tempLogo: null,
            /** @type {string} pivotSelectedKey */
            pivotSelectedKey: me?.profileId === EProfile.CustomerAdmin ? 'Address' : 'Data',
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        const {
            match, setCommand, lang, command,
        } = this.props
        const { pivotSelectedKey } = this.state

        if (match?.params?.clientId !== prevProps?.match?.params?.clientId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(command.find(x => x.key === 'edit') ? this.commandRead : this.commandEdit)
        }

        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.clientsHandlerRemoveById?.cancel()
        this.clientsHandlerGetById?.cancel()
        this.clientsHandlerUpsert?.cancel()
        this.handlerUploadFile?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, me: { profileId } } = this.props

        if (profileId === EProfile.CustomerAdmin) {
            setBreadcrumb([])
            return
        }

        setBreadcrumb([
            { text: this.tKey('clients'), key: 'client', href: '/clients' },
            {
                text: match?.params?.clientId ? `${this.tKey('client')} n°${match?.params?.clientId}` : this.tKey('newClient'),
                key: 'one-client',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, clientsHandler, setMessageBar, me,
        } = this.props

        const { pivotSelectedKey } = this.state

        /**
         * @type {import("@fluentui/react").ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
                isVisible: pivotSelectedKey === 'Data',
            },
        ].filter(x => x.isVisible)

        /**
         * @type {import("@fluentui/react").ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorClient(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.clientId,
                isVisible: pivotSelectedKey === 'Data',
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
                isVisible: pivotSelectedKey === 'Data',
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteClient'),
                        subTitle: this.tKey('sureDeleteClient'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.clientsHandlerRemoveById = clientsHandler.removeById(match?.params?.clientId)
                                    await this.clientsHandlerRemoveById.fetch()
                                    history.push('/clients')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.clientId,
                isVisible: pivotSelectedKey === 'Data' && me.profileId !== EProfile.CustomerAdmin,
            },
        ].filter(x => x.isVisible)
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, clientsHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.clientId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.client) // If object came from history push with a create
                    this.setState({
                        item: location.state?.client,
                        iniItem: location.state?.client,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        this.clientsHandlerGetById = clientsHandler.getById(match?.params?.clientId)
                        const client = await this.clientsHandlerGetById.fetch()
                        this.setState({
                            item: client,
                            iniItem: client,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Client(),
                    iniItem: new Client(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, clientsHandler, filesHandler, match, setMessageBar,
        } = this.props
        const { item, tempLogo } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.clientsHandlerUpsert = clientsHandler.upsert(item, match?.params?.clientId)
                const client = await this.clientsHandlerUpsert.fetch()

                if (tempLogo) {
                    setMessageBar({ isDisplayed: false })
                    this.handlerUploadFile = filesHandler.uploadFile(
                        client?.clientId,
                        EFileFolder.ClientLogo,
                        tempLogo,
                    )
                    client.logo = await this.handlerUploadFile.fetch()
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' })
                }

                this.setState({
                    item: client,
                    iniItem: client,
                    status: Status.RESOLVED,
                    errorField: new ErrorClient(),
                })

                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.clientId)
                    history.replace(`/clients/${client.clientId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorClient>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField,
            tempLogo, pivotSelectedKey,
        } = this.state
        const {
            param, filesHandler, addressesHandler, lang, supportContactsHandler, clientsHandler, companiesHandler,
            setMessageBar, me, setMe, setModal,
        } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main>
                <Pivot
                    styles={{ itemContainer: { paddingTop: '20px' } }}
                    selectedKey={pivotSelectedKey}
                    onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                >
                    {me.profileId !== EProfile.CustomerAdmin && (
                        <PivotItem
                            headerText={this.tKey('company')}
                            itemIcon="PageData"
                            itemKey="Data"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <form
                                onSubmit={ev => {
                                    ev.preventDefault()
                                    this.upsert()
                                }}
                            >
                                <Card
                                    title={this.tKey('information')}
                                    iconName="PageData"
                                >
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('name')}
                                                placeholder={this.tKey('name')}
                                                value={item.name}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, name: newVal } })}
                                                errorMessage={this.tObj(parseJson(errorField.name))}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('tmsCode')}
                                                placeholder={this.tKey('tmsCode')}
                                                value={item.clientTmsId}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, clientTmsId: newVal } })}
                                                errorMessage={this.tObj(errorField.clientTmsId)}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            {item.clientId
                                                ? (
                                                    <PreviewFileInput
                                                        handler={filesHandler}
                                                        fileFolderId={EFileFolder.ClientLogo}
                                                        entityId={item.clientId}
                                                        isReadOnly={isReadOnly}
                                                        isRequired={false}
                                                        file={item.logo}
                                                        updateItem={file => this.setState({ item: { ...item, logo: file } })}
                                                    />
                                                )
                                                : (
                                                    <FileInput
                                                        label={this.tKey('logo')}
                                                        isReadOnly={false}
                                                        isDisabled={status === Status.PENDING}
                                                        fileName={tempLogo?.name}
                                                        tooltipContent=""
                                                        onDownload={() => null}
                                                        onUpload={file => Promise.resolve(this.setState({ tempLogo: file }))}
                                                        onDelete={() => Promise.resolve(this.setState({ tempLogo: null }))}
                                                    />
                                                )}
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        {me.company.companyRule.companyRuleWorkflowStepIds.includes(EWorkflowStep.OperatorsValidation) && (
                                            <Columns.Column size="one-quarter">
                                                <Toggle
                                                    label={this.tKey('isClientRisky')}
                                                    onText={this.tKey('yes')}
                                                    offText={this.tKey('no')}
                                                    checked={item.isRisky}
                                                    onChange={(_ev, checked) => this.setState({ item: { ...item, isRisky: checked } })}
                                                    disabled={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                        <Columns.Column size="one-quarter">
                                            <Toggle
                                                label={this.tKey('isOrderSendingEmailAutomatic')}
                                                onText={this.tKey('yes')}
                                                offText={this.tKey('no')}
                                                checked={item.isOrderSendingEmailAutomatic}
                                                onChange={(_ev, checked) => this.setState({ item: { ...item, isOrderSendingEmailAutomatic: checked } })}
                                                disabled={isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <Toggle
                                                label={this.tKey('confirmationEmailEntry')}
                                                onText={this.tKey('yes')}
                                                offText={this.tKey('no')}
                                                checked={item.confirmationEmailEntry}
                                                onChange={(_ev, checked) => this.setState({ item: { ...item, confirmationEmailEntry: checked } })}
                                                disabled={isReadOnly}
                                            />
                                        </Columns.Column>
                                        {me.companyId === ECompany.Raud && (
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('labelFormat')}
                                                    placeholder={this.tKey('select')}
                                                    options={[
                                                        { key: 1, text: '1' },
                                                        { key: 2, text: '2' },
                                                        { key: 4, text: '4' },
                                                        { key: 6, text: '6' },
                                                        { key: 8, text: '8' },
                                                        { key: 10, text: '10' },
                                                        { key: 12, text: '12' }]}
                                                    selectedKey={item.labelFormat}
                                                    onChange={(ev, option) => this.setState({ item: { ...item, labelFormat: option.key } })}
                                                    errorMessage={this.tObj(errorField.labelFormat)}
                                                    required={!isReadOnly}
                                                    borderless={isReadOnly}
                                                    readOnly={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                        {me.companyId === ECompany.Raud && (
                                            <Columns.Column size="one-quarter">
                                                <Toggle
                                                    label={this.tKey('useStocks')}
                                                    onText={this.tKey('yes')}
                                                    offText={this.tKey('no')}
                                                    checked={item.useStocks}
                                                    onChange={(_ev, checked) => this.setState({ item: { ...item, useStocks: checked } })}
                                                    disabled={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                    </Columns>
                                    <Columns />
                                </Card>
                                <br />
                                <Card
                                    title={this.tKey('address')}
                                    iconName="Location"
                                >
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('street')}
                                                placeholder={this.tKey('street')}
                                                value={item.street}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, street: newVal } })}
                                                errorMessage={this.tObj(parseJson(errorField.street))}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('zipcode')}
                                                placeholder={this.tKey('zipcode')}
                                                value={item.zipCode}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, zipCode: newVal } })}
                                                errorMessage={this.tObj(parseJson(errorField.zipCode))}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('city')}
                                                placeholder={this.tKey('city')}
                                                value={item.city}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, city: newVal } })}
                                                errorMessage={this.tObj(parseJson(errorField.city))}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label={this.tKey('country')}
                                                placeholder={this.tKey('research')}
                                                options={param.countries.map(x => ({ key: x.key, text: this.tObj(parseJson(x.text)) }))}
                                                selectedKey={item.countryId}
                                                onChange={(ev, option) => this.setState({ item: { ...item, countryId: option.key } })}
                                                errorMessage={tObj(errorField.countryId)}
                                                required={!isReadOnly}
                                                borderless={isReadOnly}
                                                readOnly={isReadOnly}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <PhoneField
                                                label={this.tKey('phoneNumber')}
                                                placeholder={this.tKey('phoneNumber')}
                                                value={item.phoneNumber}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, phoneNumber: newVal } })}
                                                required={!isReadOnly}
                                                errorMessage={this.tObj(parseJson(errorField.phoneNumber))}
                                                type="tel"
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('email')}
                                                placeholder={this.tKey('email')}
                                                value={item.email}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                                errorMessage={this.tObj(parseJson(errorField.email))}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                </Card>
                                <br />
                                <Card
                                    title={this.tKey('orderSettings')}
                                    iconName="DocumentManagement"
                                >

                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <Toggle
                                                label={this.tKey('canCreateOrder')}
                                                onText={this.tKey('yes')}
                                                offText={this.tKey('no')}
                                                checked={item.canCreateOrder}
                                                onChange={(_ev, checked) => this.setState({ item: { ...item, canCreateOrder: checked } })}
                                                disabled={isReadOnly}
                                            />
                                        </Columns.Column>
                                        {me.companyId === ECompany.Raud && (
                                            <Columns.Column size="one-quarter">
                                                <Toggle
                                                    label={this.tKey('isFootageInformable')}
                                                    onText={this.tKey('yes')}
                                                    offText={this.tKey('no')}
                                                    checked={item.isFootageInformable}
                                                    onChange={(_ev, checked) => this.setState({ item: { ...item, isFootageInformable: checked } })}
                                                    disabled={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                        {me.companyId === ECompany.Raud && (
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('deliveryDateType')}
                                                    placeholder={this.tKey('select')}
                                                    options={param.deliveryDateTypes.map(x => ({ key: x.key, text: this.tObj(parseJson(x.text)) }))}
                                                    selectedKey={item.deliveryDateTypeId}
                                                    onChange={(ev, option) => this.setState({ item: { ...item, deliveryDateTypeId: option.key } })}
                                                    errorMessage={tObj(errorField.deliveryDateTypeId)}
                                                    required={!isReadOnly}
                                                    borderless={isReadOnly}
                                                    readOnly={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                        {item.deliveryDateTypeId === EdeliveryDateType.MandatoryDate && (
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('minDays')}
                                                    placeholder={this.tKey('select')}
                                                    options={paramMinDays}
                                                    selectedKey={item.minDays}
                                                    onChange={(ev, option) => this.setState({ item: { ...item, minDays: option.key } })}
                                                    errorMessage={tObj(errorField.minDays)}
                                                    required={!isReadOnly}
                                                    borderless={isReadOnly}
                                                    readOnly={isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                    </Columns>
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label={this.tKey('pickupType')}
                                                readOnly={isReadOnly}
                                                options={param.orderTypes.map(x => ({ key: x.key, text: this.tObj(parseJson(x.text)) }))}
                                                selectedKey={item.orderTypeIds}
                                                onChange={(ev, option) => {
                                                    this.setState({
                                                        item: {
                                                            ...item,
                                                            orderTypeIds: this.getUpdatedList(item.orderTypeIds, option),
                                                        },
                                                    })
                                                }}
                                                errorMessage={this.tObj(parseJson(errorField.orderTypeIds))}
                                                disabled={!item.canCreateOrder}
                                                multiSelect
                                                required
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="half">
                                            <FilteredVirtualCombobox
                                                label={this.tKey('visibleOperatingCenters')}
                                                readOnly={isReadOnly}
                                                options={param.addresses.filter(x => x.isPlatform)}
                                                selectedKey={item.visibleOperatingCenterIds}
                                                onChange={(ev, option) => {
                                                    this.setState({
                                                        item: {
                                                            ...item,
                                                            visibleOperatingCenterIds: this.getUpdatedList(item.visibleOperatingCenterIds, option),
                                                        },
                                                    })
                                                }}
                                                errorMessage={this.tObj(parseJson(errorField.visibleOperatingCenterIds))}
                                                disabled={!item.canCreateOrder}
                                                multiSelect
                                            />
                                        </Columns.Column>
                                    </Columns>
                                    {me.companyId !== ECompany.JetFreeze && (
                                        <Columns>
                                            <Columns.Column size="half">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('authorizedDdeliveryTypes')}
                                                    readOnly={isReadOnly}
                                                    options={param.deliveryTypes.map(x => ({ ...x, text: `${x.shortName} - ${this.tObj(parseJson(x.text))}` }))}
                                                    selectedKey={item.authorizedDdeliveryTypeIds}
                                                    onChange={(ev, option) => {
                                                        const updatedList = this.getUpdatedList(item.authorizedDdeliveryTypeIds, option)
                                                        const newItem = {
                                                            ...item,
                                                            authorizedDdeliveryTypeIds: updatedList,
                                                        }
                                                        if (!updatedList.includes(item.defaultDeliveryTypeId))
                                                            item.defaultDeliveryTypeId = null
                                                        this.setState({ item: newItem })
                                                    }}
                                                    errorMessage={this.tObj(parseJson(errorField.authorizedDdeliveryTypeIds))}
                                                    required={item.canCreateOrder && !isReadOnly}
                                                    disabled={!item.canCreateOrder}
                                                    multiSelect
                                                />
                                            </Columns.Column>
                                            {
                                                param.deliveryTypes.some(
                                                    dt => dt.hasComfourDataRequired && item.authorizedDdeliveryTypeIds.includes(+dt.key),
                                                ) && (
                                                    <Columns.Column size="one-quarter">
                                                        <TextField
                                                            label={this.tKey('comfourNumber')}
                                                            placeholder={this.tKey('comfourNumber')}
                                                            value={item.comfourNumber || ''}
                                                            readOnly={isReadOnly}
                                                            borderless={isReadOnly}
                                                            onChange={(ev, newVal) => this.setState({ item: { ...item, comfourNumber: newVal } })}
                                                            errorMessage={this.tObj(parseJson(errorField.comfourNumber))}
                                                            required={item.canCreateOrder && !isReadOnly}
                                                            disabled={!item.canCreateOrder}
                                                        />
                                                    </Columns.Column>
                                                )
                                            }
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('defaultDeliveryType')}
                                                    readOnly={isReadOnly}
                                                    options={param.deliveryTypes
                                                        .filter(x => item.authorizedDdeliveryTypeIds.includes(+x.key))
                                                        .map(x => ({ ...x, text: `${x.shortName} - ${this.tObj(parseJson(x.text))}` }))}
                                                    selectedKey={item.defaultDeliveryTypeId}
                                                    onChange={(ev, option) => {
                                                        this.setState({
                                                            item: {
                                                                ...item,
                                                                defaultDeliveryTypeId: +option.key,
                                                            },
                                                        })
                                                    }}
                                                    errorMessage={this.tObj(parseJson(errorField.defaultDeliveryTypeId))}
                                                    disabled={!item.canCreateOrder}
                                                />
                                            </Columns.Column>
                                        </Columns>
                                    )}
                                </Card>
                                <br />
                                <Card
                                    title={this.tKey('fileSettings')}
                                    iconName="DocumentManagement"
                                >
                                    <Text>
                                        <Icon iconName="Warning" />
                                        {' '}
                                        {this.tKey('warninFileSettings')}
                                    </Text>
                                    <br />
                                    <br />
                                    {item.fileCategories.map((fileCategory, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <React.Fragment key={`${fileCategory.fileCategoryId}_${i}`}>
                                            <Columns>
                                                <Columns.Column size="one-quarter">
                                                    <TextField
                                                        label={this.tKey('nameEn')}
                                                        placeholder={this.tKey('nameEn')}
                                                        value={this.parseJson(fileCategory.name)?.en || ''}
                                                        readOnly={isReadOnly}
                                                        borderless={isReadOnly}
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.name`]))}
                                                        onChange={(ev, newVal) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                name: JSON.stringify({ ...(this.parseJson(fileCategory.name)), en: newVal }),
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                        required={!isReadOnly}
                                                    />
                                                </Columns.Column>
                                                <Columns.Column size="one-quarter">
                                                    <TextField
                                                        label={this.tKey('nameFr')}
                                                        placeholder={this.tKey('nameFr')}
                                                        value={this.parseJson(fileCategory.name)?.fr || ''}
                                                        readOnly={isReadOnly}
                                                        borderless={isReadOnly}
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.name`]))}
                                                        onChange={(ev, newVal) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                name: JSON.stringify({ ...(this.parseJson(fileCategory.name)), fr: newVal }),
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                        required={!isReadOnly}
                                                    />
                                                </Columns.Column>
                                                <Columns.Column size="one-quarter">
                                                    <TextField
                                                        label={this.tKey('nameNl')}
                                                        placeholder={this.tKey('nameNl')}
                                                        value={this.parseJson(fileCategory.name)?.nl || ''}
                                                        readOnly={isReadOnly}
                                                        borderless={isReadOnly}
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.name`]))}
                                                        onChange={(ev, newVal) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                name: JSON.stringify({ ...parseJson(fileCategory.name), nl: newVal }),
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                        required={!isReadOnly}
                                                    />
                                                </Columns.Column>
                                                <Columns.Column size="one-quarter">
                                                    <FilteredVirtualCombobox
                                                        label={this.tKey('xmlCode')}
                                                        placeholder={this.tKey('xmlCode')}
                                                        readOnly={isReadOnly}
                                                        options={param.fileCategoryXmlCodes?.map(x => ({
                                                            key: x.key,
                                                            text: this.tObj(this.parseJson(x.text)),
                                                        }))}
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.fileCategoryXmlCodeId`]))}
                                                        selectedKey={fileCategory.fileCategoryXmlCodeId}
                                                        onChange={(ev, option) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                fileCategoryXmlCodeId: option.key,
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                        required={!isReadOnly}
                                                    />
                                                </Columns.Column>
                                            </Columns>
                                            <Columns>
                                                <Columns.Column size="one-quarter">
                                                    <FilteredVirtualCombobox
                                                        label={this.tKey('countriesMandatory')}
                                                        placeholder={this.tKey('countriesMandatory')}
                                                        readOnly={isReadOnly}
                                                        options={param.countries?.map(x => ({
                                                            key: x.key,
                                                            text: this.tObj(this.parseJson(x.text)),
                                                            disabled: fileCategory.optionalCountriesId?.some(y => y === x.key),
                                                        })).sort((a, b) => a.text.localeCompare(b.text))}
                                                        multiSelect
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.requiredCountriesId`]))}
                                                        selectedKey={fileCategory.requiredCountriesId ?? []}
                                                        onChange={(ev, option) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                requiredCountriesId: this.getUpdatedList(fileCategory.requiredCountriesId, option),
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                    />
                                                </Columns.Column>
                                                <Columns.Column size="one-quarter">
                                                    <FilteredVirtualCombobox
                                                        label={this.tKey('countriesOptionnal')}
                                                        placeholder={this.tKey('countriesOptionnal')}
                                                        readOnly={isReadOnly}
                                                        options={param.countries?.map(x => ({
                                                            key: x.key,
                                                            text: this.tObj(this.parseJson(x.text)),
                                                            disabled: fileCategory.requiredCountriesId?.some(y => y === x.key),
                                                        })).sort((a, b) => a.text.localeCompare(b.text))}
                                                        multiSelect
                                                        errorMessage={this.tObj(this.parseJson(errorField[`fileCategories.${i}.optionalCountriesId`]))}
                                                        selectedKey={fileCategory.optionalCountriesId ?? []}
                                                        onChange={(ev, option) => {
                                                            const newFileCategories = [...item.fileCategories]
                                                            newFileCategories[i] = {
                                                                ...item.fileCategories[i],
                                                                optionalCountriesId: this.getUpdatedList(fileCategory.optionalCountriesId, option),
                                                            }
                                                            this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                        }}
                                                    />
                                                </Columns.Column>
                                                <Columns.Column size="one-quarter" />
                                                <Columns.Column size="one-quarter">
                                                    <Label>&#8203;</Label>
                                                    <DefaultButton
                                                        text={this.tKey('delete')}
                                                        iconProps={{ iconName: 'Delete' }}
                                                        onClick={() => this.setState({
                                                            item: {
                                                                ...item, fileCategories: item.fileCategories.filter((_, y) => y !== i),
                                                            },
                                                        })}
                                                        disabled={isReadOnly}
                                                    />
                                                </Columns.Column>
                                            </Columns>
                                            {i !== item.fileCategories.length - 1 && <hr />}
                                        </React.Fragment>
                                    ))}
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        onClick={() => this.setState({ item: { ...item, fileCategories: item.fileCategories.concat([new FileCategory()]) } })}
                                        text={this.tKey('add')}
                                        disabled={isReadOnly}
                                    />
                                </Card>
                                <br />
                                {me.companyId === ECompany.Raud && (
                                    <Card
                                        title={this.tKey('activityCodes')}
                                        iconName="DocumentManagement"
                                    >
                                        {!!errorField.clientActivities && (
                                            <>
                                                <div
                                                    style={{ color: '#a80000' }}
                                                >
                                                    {this.tObj(parseJson(errorField.clientActivities))}
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        {item.clientActivities.map((activity, i) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <React.Fragment key={`${activity.clientActivityId}_${i}`}>
                                                <Columns>
                                                    <Columns.Column size="one-quarter">
                                                        <TextField
                                                            label={this.tKey('code')}
                                                            placeholder={this.tKey('code')}
                                                            value={activity.code}
                                                            readOnly={isReadOnly}
                                                            borderless={isReadOnly}
                                                            errorMessage={this.tObj(this.parseJson(errorField[`clientActivities.${i}.code`]))}
                                                            onChange={(ev, newVal) => {
                                                                const newActivities = [...item.clientActivities]
                                                                newActivities[i] = {
                                                                    ...item.clientActivities[i],
                                                                    code: newVal,
                                                                }
                                                                this.setState({ item: { ...item, clientActivities: newActivities } })
                                                            }}
                                                            required={!isReadOnly}
                                                        />
                                                    </Columns.Column>
                                                    <Columns.Column size="one-quarter">
                                                        <Toggle
                                                            label={this.tKey('isDefault')}
                                                            onText={this.tKey('yes')}
                                                            offText={this.tKey('no')}
                                                            checked={activity.isDefault}
                                                            onChange={(ev, checked) => {
                                                                let newActivities = [...item.clientActivities]
                                                                newActivities[i] = {
                                                                    ...item.clientActivities[i],
                                                                    isDefault: checked,
                                                                }
                                                                if (checked)
                                                                    newActivities = newActivities.map((x, y) => ({
                                                                        ...x,
                                                                        isDefault: y === i,
                                                                    }))

                                                                this.setState({ item: { ...item, clientActivities: newActivities } })
                                                            }}
                                                            disabled={isReadOnly}
                                                        />
                                                    </Columns.Column>
                                                    <Columns.Column size="one-quarter">
                                                        <Label>&#8203;</Label>
                                                        <DefaultButton
                                                            text={this.tKey('delete')}
                                                            iconProps={{ iconName: 'Delete' }}
                                                            onClick={() => this.setState({
                                                                item: {
                                                                    ...item, clientActivities: item.clientActivities.filter((_, y) => y !== i),
                                                                },
                                                            })}
                                                            disabled={isReadOnly}
                                                        />
                                                    </Columns.Column>
                                                </Columns>
                                                {i !== item.clientActivities.length - 1 && <hr />}
                                            </React.Fragment>
                                        ))}
                                        <DefaultButton
                                            iconProps={{ iconName: 'Add' }}
                                            // eslint-disable-next-line max-len
                                            onClick={() => this.setState({ item: { ...item, clientActivities: item.clientActivities.concat([new ClientActivity()]) } })}
                                            text={this.tKey('add')}
                                            disabled={isReadOnly}
                                        />
                                    </Card>
                                )}
                                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                <button
                                    type="submit"
                                    style={{ display: 'none' }}
                                    ref={this.submitInput}
                                    tabIndex={-1}
                                />
                            </form>
                        </PivotItem>
                    )}
                    <PivotItem
                        headerText={this.tKey('addresses')}
                        itemIcon="MapPin"
                        itemKey="Address"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotAddresses
                            lang={lang}
                            items={item.addresses}
                            addressesHandler={addressesHandler}
                            indexes={{ companyId: null, clientId: item.clientId }}
                            param={param}
                            companyRules={me.company.companyRule}
                            setModal={setModal}
                            setMessageBar={setMessageBar}
                            setMe={setMe}
                            me={me}
                        />
                    </PivotItem>
                    {me.profileId !== EProfile.CustomerAdmin && (
                        <PivotItem
                            headerText={this.tKey('contacts')}
                            itemIcon="Contact"
                            itemKey="Contacts"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <SupportContacts
                                supportContacts={item.supportContacts}
                                lang={lang}
                                canEdit
                                supportContactsHandler={supportContactsHandler}
                                defaultData={{ clientId: item.clientId }}
                                supportContactTypes={param.supportContactTypes}
                                onError={() => setMessageBar({ isDisplayed: false })}
                            />
                        </PivotItem>
                    )}
                    {me.companyId !== ECompany.Raud && (
                        <PivotItem
                            headerText={this.tKey('supports')}
                            itemIcon="PageData"
                            itemKey="supports"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <PivotSupports
                                lang={lang}
                                supports={item.supports}
                            />
                        </PivotItem>
                    )}
                    {me.companyId === ECompany.Raud && (
                        <PivotItem
                            headerText={this.tKey('articles')}
                            itemIcon="PageData"
                            itemKey="articles"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <PivotArticles
                                clientId={item.clientId}
                                clientHanlder={clientsHandler}
                                lang={lang}
                                articles={item.articles}
                                isAdmin={[EProfile.SuperAdmin, EProfile.Admin].includes(me.profileId)}
                            />
                        </PivotItem>
                    )}
                    <PivotItem
                        headerText={this.tKey('operatingCenters')}
                        itemIcon="PageData"
                        itemKey="operatingCenters"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotOperatingCenters
                            lang={lang}
                            companiesHandler={companiesHandler}
                            filesHandler={filesHandler}
                            companyId={item.companyId}
                            setModal={setModal}
                            readOnly
                        />
                    </PivotItem>
                </Pivot>
            </main>
        )
    }
}

Idclients.prototype.getUpdatedList = getUpdatedList
Idclients.prototype.tKey = tKey
Idclients.prototype.tObj = tObj
Idclients.prototype.parseJson = parseJson
