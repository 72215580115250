import React, { PureComponent } from 'react'
import {
    PrimaryButton,
    Spinner,
    SpinnerSize,
    TextField,
    IconButton,
    DefaultButton,
    DialogType,
    Dialog,
    DialogFooter,
    Text,
    TooltipHost,
    DirectionalHint,
    TooltipDelay,
    Label,
} from '@fluentui/react'
import { tKey } from 'helpers/methods/translate'

/**
 * @typedef {object} FileInputProps
 * @property {boolean=} isReadOnly Is readonly ?
 * @property {boolean=} isDisabled Is force disabled ?
 * @property {string=} fileName File name, if set act like there is a file
 * @property {string} tooltipContent Tooltip text to display
 * @property {string=} errorMessage Error message to be displayed
 * @property {Function=} onDownload Callback to download button
 * @property {Function=} onOpen Callback to open button
 * @property {function(globalThis.File)=} onUpload Callback to upload button
 * @property {Function=} onDelete Callback to delete button
 * @property {boolean=} isRequired Is required ?
 * @property {string=} label Label to display
 * @augments {PureComponent<FileInputProps>}}
 */
export default class FileInput extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {boolean} Is uploading running */
            isUploading: false,
            /** @type {boolean} Is deleting running */
            isDeleteing: false,
            /** @type {boolean} Is dowloading running */
            isDownloading: false,
            /** @type {boolean} Is show delete modal */
            showDialog: false,
        }
    }

    render() {
        const {
            isDownloading, isUploading, isDeleteing, showDialog,
        } = this.state
        const {
            errorMessage,
            isReadOnly = true,
            isDisabled,
            fileName,
            tooltipContent,
            onDownload = () => Promise.resolve(),
            onOpen,
            onUpload = () => Promise.resolve(),
            onDelete = () => Promise.resolve(),
            isRequired,
            label,
        } = this.props

        if (isReadOnly)
            return (
                <div>
                    <Label required={isRequired}>{label}</Label>
                    <div className="flex-row flex-start">
                        <TooltipHost
                            content={tooltipContent}
                            directionalHint={DirectionalHint.bottomCenter}
                            delay={TooltipDelay.zero}
                        >
                            <DefaultButton
                                split
                                text={this.tKey('download')}
                                iconProps={{ iconName: 'Download' }}
                                disabled={!fileName || isDownloading || isDisabled}
                                onClick={() => {
                                    this.setState({ isDownloading: true })
                                    onDownload().finally(() => this.setState({ isDownloading: false }))
                                }}
                                menuProps={onOpen ? {
                                    items: [
                                        {
                                            key: 'download',
                                            text: this.tKey('download'),
                                            iconProps: { iconName: 'Download' },
                                            onClick: () => {
                                                this.setState({ isDownloading: true })
                                                onDownload().finally(() => this.setState({ isDownloading: false }))
                                            },
                                        },
                                        {
                                            key: 'open',
                                            text: this.tKey('view'),
                                            disabled: isDisabled,
                                            iconProps: { iconName: 'OpenInNewTab' },
                                            onClick: () => {
                                                this.setState({ isDownloading: true })
                                                onOpen().finally(() => this.setState({ isDownloading: false }))
                                            },
                                        },
                                    ],
                                } : undefined}
                            />
                        </TooltipHost>
                        {isDownloading && (
                            <Spinner size={SpinnerSize.small} />
                        )}

                    </div>
                    <Text
                        variant="small"
                        block
                    >
                        {fileName}
                    </Text>
                </div>
            )

        return (
            <div
                style={{
                    position: 'relative',
                    // paddingBottom: !!this.props.errorMessage ? "15px" : undefined
                }}
            >
                <Label required={isRequired}>{label}</Label>
                <div className="flex-row flex-start">
                    <PrimaryButton
                        iconProps={{ iconName: fileName ? 'Download' : 'Upload' }}
                        text={this.tKey(fileName ? 'download' : 'upload')}
                        onClick={() => {
                            if (fileName) {
                                this.setState({ isDownloading: true })
                                onDownload().finally(() => this.setState({ isDownloading: false }))
                            } else {
                                this.uploadFile.value = null
                                this.uploadFile.click()
                            }
                        }}
                        disabled={isUploading || isDownloading || isDisabled}
                    />
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        disabled={isUploading || !fileName || isDisabled}
                        onClick={() => {
                            this.setState({ showDialog: true })
                        }}
                    />
                    {isUploading && (
                        <Spinner
                            size={SpinnerSize.small}
                            labelPosition="right"
                        />
                    )}
                    <input
                        type="file"
                        ref={input => { this.uploadFile = input }}
                        onChange={e => {
                            if (e.target?.files?.[0]) {
                                this.setState({ isUploading: true })
                                onUpload(e.target.files[0]).finally(() => this.setState({ isUploading: false }))
                            }
                        }}
                        style={{ display: 'none' }}
                    />
                </div>
                <Text
                    variant="small"
                    block
                >
                    {fileName}
                </Text>
                {
                    errorMessage
                    && (
                        <TextField
                            errorMessage={errorMessage}
                            styles={{ wrapper: { display: 'none' } }}
                        />
                    )
                }
                <Dialog
                    hidden={!showDialog}
                    onDismiss={() => this.setState({ showDialog: false })}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: this.tKey('deleteDocument'),
                        subText: this.tKey('deleteDocumentSub'),
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}
                >
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => this.setState({ showDialog: false })}
                            text={this.tKey('cancel')}
                            disabled={isDeleteing}
                        />
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ isDeleteing: true })
                                onDelete().finally(() => this.setState({ isDeleteing: false, showDialog: false }))
                            }}
                            disabled={isDeleteing}
                        >
                            {this.tKey('yes')}
                            {' '}
                            {isDeleteing && (
                                <Spinner
                                    size={SpinnerSize.small}
                                    labelPosition="right"
                                />
                            )}
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
                {isRequired
                    && (
                        <TextField
                            value={fileName || ''}
                            onChange={() => null}
                            styles={{
                                wrapper: {
                                    opacity: 0,
                                },
                                root: {
                                    position: 'absolute',
                                    bottom: 0,
                                    pointerEvents: 'none',
                                },
                            }}
                            tabIndex={-1}
                            required
                        />
                    )}
            </div>
        )
    }
}

FileInput.prototype.tKey = tKey
