import React, {
    useCallback, useEffect, useMemo,
    useRef,
    useState,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, Text, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Status from 'types/status'
import OperatingCenter from 'requests/objects/operatingCenter'
import styles from 'styles/components/pages/companies/operating-centers/operating-center-list.module.scss'
import flattenObj from 'helpers/methods/flattenObj'
import PhoneField from 'components/inputs/phoneField'
import FilesHandler from 'requests/handlers/filesHandler'
import EFileFolder from 'types/files/enums/fileFolder'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'

/**
 * @callback EditOperatingCenterCallback
 * @param {number} operatingCenterId operatingCenterId
 */

/**
 * @callback DeleteOperatingCenterCallback
 * @param {OperatingCenter} operatingCenter operatingCenter
 */

/**
 * OperatingCenterList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {OperatingCenter[]} props.items items
 * @param {Status} props.status status
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {EditOperatingCenterCallback} props.editOperatingCenter editOperatingCenter
 * @param {DeleteOperatingCenterCallback} props.deleteOperatingCenter deleteOperatingCenter
 * @param {boolean} [props.readOnly] readOnly
 * @returns {JSX.Element} Element
 */
export default function OperatingCenterList({
    lang,
    items,
    status,
    filesHandler,
    editOperatingCenter,
    deleteOperatingCenter,
    readOnly = false,
}) {
    const { tKey } = useTranslate({ lang })
    const [imgSources, setImgSources] = useState({})

    /** @type {object} */
    const imgRefs = useRef({})

    const operatingCenters = useMemo(() => items.map(x => flattenObj(x)), [items])

    const ActionButtons = useCallback(
        operatingCenter => (
            <div className={styles['action-buttons']}>
                <IconButton
                    iconProps={{ iconName: 'Edit' }}
                    title={tKey('edit')}
                    onClick={() => editOperatingCenter(operatingCenter.operatingCenterId)}
                />
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={tKey('delete')}
                    onClick={() => deleteOperatingCenter(operatingCenter)}
                />
            </div>
        ), [deleteOperatingCenter, editOperatingCenter, tKey],
    )

    const renderLogo = useCallback(
        /**
         * @param {OperatingCenter} operatingCenter operatingCenter
         * @returns {JSX.Element} Element
         */
        operatingCenter => {
            if (imgSources[operatingCenter.logoId])
                return (
                    <img
                        src={imgSources[operatingCenter.logoId]}
                        alt="Logo"
                    />

                )
            return (<div />)
        },
        [imgSources],
    )

    useEffect(() => {
        if (!items.length)
            return
        (async () => {
            try {
                const res = await items.reduce(async (promise, operatingCenter) => {
                    const results = await promise
                    const fileId = operatingCenter.logoId
                    if (fileId) {
                        imgRefs.current[fileId] = filesHandler.getFile(EFileFolder.OperatingCenterLogo, fileId)
                        const img = await imgRefs.current[fileId].fetch()
                        if (img)
                            results[fileId] = window.URL.createObjectURL(img)
                    }
                    return results
                }, Promise.resolve({}))
                setImgSources(res)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })()
    }, [setImgSources, filesHandler, items])

    const columns = useMemo(() => {
        /** @type {import('@fluentui/react').IColumn[]} */
        const cols = [
            {
                key: 'location',
                name: tKey('location'),
                fieldName: 'location',
                minWidth: 50,
                maxWidth: 350,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <div
                        style={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingRight: '2em',
                        }}
                    >
                        <b>
                            {item.name}
                        </b>
                        <span>
                            {item.street}
                            {' '}
                            {item.zipCode}
                            {' '}
                            {item.city}
                        </span>
                    </div>
                ),
            },
            {
                key: 'shortName',
                name: tKey('abbreviation'),
                fieldName: 'shortName',
                minWidth: 50,
                maxWidth: 120,
                isResizable: true,
            },
            {
                key: 'email',
                name: tKey('email'),
                fieldName: 'email',
                minWidth: 50,
                maxWidth: 225,
                isResizable: true,
            },
            {
                key: 'phone',
                name: tKey('phoneNumber'),
                fieldName: 'phone',
                minWidth: 50,
                maxWidth: 200,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (item.phone ? (
                    <PhoneField
                        value={item.phone}
                        readOnly
                        borderless
                        type="tel"
                    />
                ) : null),
            },
            {
                key: 'tmsCode',
                name: tKey('tmsCode'),
                fieldName: 'operatingCenterTmsId',
                minWidth: 50,
                maxWidth: 200,
                isResizable: true,
            },
            {
                key: 'code',
                name: tKey('code'),
                fieldName: 'code',
                minWidth: 20,
                maxWidth: 50,
                isResizable: true,
            },
            {
                key: 'logo',
                name: tKey('logo'),
                fieldName: 'logo',
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                onRender: renderLogo,
            },
        ]
        if (!readOnly)
            cols.push({
                key: 'delete',
                name: '',
                minWidth: 50,
                maxWidth: 50,
                isResizable: false,
                onRender: ActionButtons,
            })
        return cols
    }, [tKey, renderLogo, readOnly, ActionButtons])

    return (
        <div className={styles['operating-center-list']}>
            <ShimmeredDetailsList
                items={operatingCenters}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
            />
            {!operatingCenters.length && status !== Status.PENDING
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey(Status.IDLE ? 'pleaseDoASearch' : 'noResultFound')}</Text>}
        </div>
    )
}
