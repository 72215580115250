import React, {
    useState, useCallback, useRef,
    useEffect,
} from 'react'
import {
    DefaultButton,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import OperatingCenter from 'requests/objects/operatingCenter'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/components/pages/companies/operating-centers/pivot-operating-centers.module.scss'
import OperatingCenterList from 'components/pages/company/operating-center/operating-center-list'
import OperatingCenterModal from 'components/pages/company/operating-center/operating-center-modal'
import FilesHandler from 'requests/handlers/filesHandler'

/**
 * Pivot operatingCenters
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {number=} props.companyId companyId
 * @param {function(import('redux/slices/common').PayloadModal):void} props.setModal Set Modal
 * @param {boolean=} props.readOnly readOnly
 * @returns {JSX.Element} Element
 */
export default function PivotOperatingCenters({
    lang,
    companiesHandler,
    filesHandler,
    companyId,
    setModal,
    readOnly = false,
}) {
    const { tKey } = useTranslate({ lang })
    const [status, setStatus] = useState(Status.IDLE)
    const [operatingCenters, setOperatingCenters] = useState([])
    const [isOperatingCenterModalVisible, setIsOperatingCenterModalVisible] = useState(false)
    const [operatingCenter, setOperatingCenter] = useState(new OperatingCenter())

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<OperatingCenter>>} */
    const deleteOperatingCenterHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<OperatingCenter[]>>} */
    const searchoperatingCentersHandler = useRef(null)

    const editOperatingCenter = useCallback(
        /**
         * Edit operatingCenter
         * @param {number} operatingCenterId operatingCenterId
         */
        operatingCenterId => {
            const operatingCenterToEdit = operatingCenters.find(x => x.operatingCenterId === operatingCenterId)
            if (operatingCenterToEdit) {
                setOperatingCenter(operatingCenterToEdit)
                setIsOperatingCenterModalVisible(true)
            }
        }, [operatingCenters],
    )

    const deleteOperatingCenter = useCallback(
        /**
         * Delete operatingCenter
         * @param {OperatingCenter} operatingCenterToDelete operatingCenterToDelete
         */
        operatingCenterToDelete => {
            setModal({
                show: true,
                title: tKey('removeOperatingCenter'),
                subTitle: tKey('sureRemoveOperatingCenter'),
                callback: async () => {
                    try {
                        deleteOperatingCenterHandler.current = companiesHandler.removeOperatingCenterById(companyId, operatingCenterToDelete.operatingCenterId)
                        const dep = await deleteOperatingCenterHandler.current.fetch()
                        setOperatingCenters(olds => olds.filter(x => x.operatingCenterId !== dep.operatingCenterId))
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }
                },
            })
        }, [companiesHandler, companyId, setModal, tKey],
    )

    const addOperatingCenter = useCallback(() => {
        setOperatingCenter(new OperatingCenter({ companyId }))
        setIsOperatingCenterModalVisible(true)
    }, [companyId])

    const search = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            searchoperatingCentersHandler.current?.cancel()
            searchoperatingCentersHandler.current = companiesHandler.getAllOperatingCenters(companyId)
            const res = await searchoperatingCentersHandler.current.fetch()
            setOperatingCenters(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setOperatingCenters([])
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [companiesHandler, companyId])

    useEffect(() => {
        search()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <main className={styles['pivot-operating-centers']}>
            <Card
                iconName="MapPin"
                title={tKey('operatingCenters')}
                headerComponent={
                    !readOnly && (
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                addOperatingCenter()
                            }}
                            text={tKey('add')}
                        />
                    )
                }
            >
                <OperatingCenterList
                    editOperatingCenter={editOperatingCenter}
                    deleteOperatingCenter={deleteOperatingCenter}
                    items={operatingCenters}
                    lang={lang}
                    status={status}
                    readOnly={readOnly}
                    filesHandler={filesHandler}
                />
            </Card>
            <OperatingCenterModal
                operatingCenterData={operatingCenter}
                isVisible={isOperatingCenterModalVisible}
                companiesHandler={companiesHandler}
                onChange={operatingCenterReturned => {
                    if (operatingCenterReturned)
                        if (!operatingCenter.operatingCenterId)
                            setOperatingCenters([...operatingCenters, operatingCenterReturned])
                        else
                            setOperatingCenters(prev => prev.map(x => (x.operatingCenterId === operatingCenterReturned.operatingCenterId
                                ? operatingCenterReturned
                                : x
                            )))

                    setIsOperatingCenterModalVisible(false)
                }}
                lang={lang}
                filesHandler={filesHandler}
            />
        </main>
    )
}
