import Card from 'components/containers/card'
import React from 'react'
import styles from 'styles/pages/_errorBoundary.module.scss'

/**
 * ErrorBoundary
 */
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Error} */
            error: null,
            /** @type {Error['stack']} */
            stack: null,
        }
    }

    static getDerivedStateFromError(error) {
        return {
            error,
            stack: (() => {
                try {
                    const split = error.stack?.split('\n')
                    if (split?.length > 1)
                        return split?.slice(1)?.join('\n')
                    return error.stack
                } catch (e) {
                    return error.stack
                }
            })(),
        }
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo)
    }

    render() {
        const { error, stack } = this.state
        const { children } = this.props

        if (error && process.env.NODE_ENV !== 'development')
            return (
                <main className={styles['error-boundary']}>
                    <div className={styles['top-area']} />
                    <div className={styles['bottom-area']} />
                    <div className={styles['left-area']} />
                    <div className={styles['right-area']} />
                    <div className={styles['main-area']}>
                        <Card
                            // eslint-disable-next-line max-len
                            title="Une erreur s'est produite, veuillez envoyer une capture d'écran de cette page ainsi que des détails sur ce qui vous amenez à cela, à l'équipe de développement"
                            iconName="StatusErrorFull"
                        >
                            <p>
                                {error.name}
                                :
                                {' '}
                                {error.message}
                            </p>
                            <p className={styles.stack}>
                                {stack}
                            </p>
                        </Card>
                    </div>
                </main>
            )

        return children
    }
}
