import React, {
    PureComponent,
} from 'react'
import {
    ConstrainMode, DetailsListLayoutMode, IconButton, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn } from 'helpers/methods/common'
import Status from 'types/status'
import Document from 'requests/objects/document'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/documents/index.module.scss'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import { Link } from 'react-router-dom'
import Time from 'helpers/methods/time'
import EFileFolder from 'types/files/enums/fileFolder'
import HandleBlob from 'helpers/methods/blob'
import File from 'requests/objects/file'
import { clientProfiles, userProfilesWithSuperAdmin } from 'types/users/profiles'
import parseJson from 'helpers/methods/parseJson'
import ECompany from 'types/companies/enums/company'

/** @debug {import("app").AppProps} */

/**
 * @augments {PureComponent<import("app").AppProps>} extends
 */
export default class IndexDocuments extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Document[]} Items found in API */
            items: [],
            /** @type {Document[]} Items found in API */
            iniItems: [],
            /** @type {import('@fluentui/react').IColumn[]} */
            documentColumns: [],
            /** @type {import('@fluentui/react').IColumn[]} */
            priceColumns: [],
        }

        // this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang, me } = this.props

        if (lang !== prevProps.lang)
            this.init()

        if (me.companyId !== prevProps.me.companyId)
            this.init()
        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.documentsHandlerGetAll?.cancel()
        this.filesHandlerGetFile?.cancel()
    }

    setColumns() {
        const documentColumns = [
            {
                key: 'name',
                name: this.tKey('wording'),
                fieldName: 'name',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (<Text style={{ color: '#00375d', fontWeight: 'bold' }}>{data.name}</Text>),
            },
            {
                key: 'createdAt',
                name: this.tKey('createdAt'),
                fieldName: 'createdAt',
                minWidth: 75,
                maxWidth: 125,
                isResizable: true,
                onRender: data => (data.createdAt ? Time(data.createdAt).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
            },
            {
                key: 'typeOfDocument',
                name: this.tKey('documentType'),
                fieldName: 'typeOfDocument',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                onRender: item => this.tObj(parseJson(item.typeOfDocument)),
            },
            {
                key: 'startDate',
                name: this.tKey('startDate'),
                fieldName: 'startDate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                onRender: data => (data.startDate ? Time(data.startDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
            },
            {
                key: 'endDate',
                name: this.tKey('endDate'),
                fieldName: 'endDate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                onRender: data => (data.endDate ? Time(data.endDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
            },
        ]
        /** @type {import('@fluentui/react').IColumn[]} */
        const priceColumns = [
            {
                key: 'priceListName',
                name: this.tKey('priceList'),
                fieldName: 'priceListName',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (<Text>{data.priceListName}</Text>),
            },
            {
                key: 'priceCode',
                name: this.tKey('tariffCode'),
                fieldName: 'priceCode',
                minWidth: 60,
                maxWidth: 110,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (<Text>{data.priceCode}</Text>),
            },
            {
                key: 'name',
                name: this.tKey('name'),
                fieldName: 'name',
                minWidth: 70,
                maxWidth: 130,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (<Text style={{ color: '#00375d', fontWeight: 'bold' }}>{data.name}</Text>),
            },
            {
                key: 'priceType',
                name: this.tKey('tariffType'),
                fieldName: 'priceType',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (<Text>{data.priceType}</Text>),
            },
            {
                key: 'startDate',
                name: this.tKey('startDate'),
                fieldName: 'startDate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                onRender: data => (data.startDate ? Time(data.startDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
            },
            {
                key: 'endDate',
                name: this.tKey('endDate'),
                fieldName: 'endDate',
                minWidth: 70,
                maxWidth: 100,
                isResizable: true,
                onRender: data => (data.endDate ? Time(data.endDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
            },
            {
                key: 'fichiers',
                name: this.tKey('files'),
                fieldName: 'file',
                minWidth: 75,
                maxWidth: 125,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: data => (
                    <>
                        <IconButton
                            iconProps={{ iconName: 'PDF' }}
                            title="PDF"
                            href={data.url}
                            target="_blank"
                        />
                        <IconButton
                            iconProps={{ iconName: 'ExcelDocument' }}
                            title={this.tKey('excelFile')}
                            href={data.excelFileUrl}
                            target="_blank"
                        />
                    </>
                ),
            },
        ]
        this.setState({
            documentColumns,
            priceColumns,
        })
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('documents'), key: 'document', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand, me } = this.props
        const commandItems = []

        if (!clientProfiles.includes(me.profileId))
            commandItems.push({
                key: 'new',
                text: this.tKey('new'),
                iconProps: { iconName: 'Add' },
                onClick: () => history.push(`/documents/${NEW_PATH}`),
            })

        setCommand(commandItems)
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setColumns()
        this.setupBreadcrumb()
        this.setupCommandBar()
        this.search()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { documentsHandler } = this.props
            try {
                this.documentsHandlerGetAll = documentsHandler.getAll()
                const documents = await this.documentsHandlerGetAll.fetch()
                this.setState({
                    items: documents.map(x => this.flattenObj(x)),
                    iniItems: documents,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Download a file
     * @param {File} file file
     */
    async downloadFile(file) {
        try {
            const { filesHandler } = this.props
            this.filesHandlerGetFile = filesHandler.getFile(EFileFolder.Document, file.fileId)
            const blob = await this.filesHandlerGetFile.fetch()
            HandleBlob.download(blob, file?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, documentColumns, iniItems, priceColumns,
        } = this.state
        const { me } = this.props

        return (
            <main className={styles.index}>
                {clientProfiles.includes(me?.profileId) && (
                    <>
                        <Card
                            title={this.tKey('prices')}
                            iconName="TextDocument"
                        >
                            <ShimmeredDetailsList
                                items={items.filter(x => x.documentTypeId === 2)}
                                columns={priceColumns}
                                selectionMode={SelectionMode.none}
                                onShouldVirtualize={() => true}
                                enableShimmer={status === Status.PENDING}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                            />
                            {!items.filter(x => x.documentTypeId === 2)?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                        </Card>
                        <br />
                        {me.companyId !== ECompany.JetFreeze && (
                            <Card
                                title={this.tKey('documents')}
                                iconName="TextDocument"
                            >
                                <ShimmeredDetailsList
                                    items={items.filter(x => x.documentTypeId !== 2)}
                                    columns={documentColumns}
                                    selectionMode={SelectionMode.none}
                                    onShouldVirtualize={() => true}
                                    enableShimmer={status === Status.PENDING}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderRow={(props, defaultRender) => {
                                        const item = iniItems.find(x => x.documentId === props.item.documentId)
                                        if (item.file?.fileId)
                                            return (
                                                <button
                                                    onClick={() => this.downloadFile(item.file)}
                                                    type="button"
                                                    style={{
                                                        all: 'unset',
                                                        cursor: props.item.fileId ? 'pointer' : undefined,
                                                        textDecoration: item.startDate > new Date() || item.endDate < new Date() ? 'line-through' : undefined,
                                                    }}
                                                >
                                                    {defaultRender(props)}
                                                </button>
                                            )
                                        if (item.url)
                                            return (
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    style={{
                                                        all: 'unset',
                                                        cursor: props.item.fileId ? 'pointer' : undefined,
                                                        textDecoration: item.startDate > new Date() || item.endDate < new Date() ? 'line-through' : undefined,
                                                    }}
                                                    rel="noreferrer"
                                                >
                                                    {defaultRender(props)}
                                                </a>
                                            )
                                        return defaultRender(props)
                                    }}
                                />
                                {!items.filter(x => x.documentTypeId !== 2)?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                            </Card>
                        )}
                    </>
                )}
                {userProfilesWithSuperAdmin.includes(me?.profileId) && (
                    <Card>
                        <ShimmeredDetailsList
                            items={items}
                            columns={documentColumns}
                            selectionMode={SelectionMode.none}
                            onShouldVirtualize={() => true}
                            enableShimmer={status === Status.PENDING}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderRow={(props, defaultRender) => (
                                <Link to={`/documents/${props.item?.documentId}`}>
                                    {defaultRender(props)}
                                </Link>
                            )}
                        />
                        {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                            && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                    </Card>
                )}
            </main>
        )
    }
}

IndexDocuments.prototype.flattenObj = flattenObj
IndexDocuments.prototype.handleRenderColumn = handleRenderColumn
IndexDocuments.prototype.tKey = tKey
IndexDocuments.prototype.tObj = tObj
