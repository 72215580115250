import React, { PureComponent } from 'react'
import {
    // @ts-ignore
    MessageBarType, TextField, ICommandBarItemProps, Toggle,
    DefaultButton, Label, Pivot, PivotItem,
} from '@fluentui/react'
import history from 'helpers/history'
// @ts-ignore
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Company, { ErrorCompany } from 'requests/objects/company'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import PreviewFileInput from 'components/pages/home-articles/[id]/previewFileInput'
import FileInput from 'components/inputs/fileInput'
import EFileFolder from 'types/files/enums/fileFolder'
import parseJson from 'helpers/methods/parseJson'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import EProfile from 'types/users/enums/profile'
import FileCategory from 'requests/objects/fileCategory'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import SupportContacts from 'components/pages/supportContacts'
import PivotLogisticConstraints from 'components/pages/company/pivot-logistic-constraints'
import ECompany from 'types/companies/enums/company'
import PivotAddresses from 'components/pages/company/pivot-addresses'
import PivotdeliveryTypes from 'components/pages/company/pivot-delivery-types'
import PivotDepartments from 'components/pages/company/department/pivot-departments'
import PivotOperatingCenters from 'components/pages/company/operating-center/pivot-operating-centers'
import PivotDeliveryTimes from 'components/pages/company/delivery-time/pivot-delivery-times'
import PivotAccessibilities from 'components/pages/company/accessibility/pivot-accessibilities'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class Idcompanies extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.companyId,
            /** @type {Company} Element find from API */
            item: new Company(),
            /** @type {Company} Initial element fond from API. Used to refresh */
            iniItem: new Company(),
            /** @type {ErrorCompany} Errors */
            errorField: new ErrorCompany(),
            /** @type {globalThis.File} tempLogo */
            tempLogo: null,
            /** @type {string} pivotSelectedKey */
            pivotSelectedKey: 'Data',
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        const {
            match, setCommand, lang, command,
        } = this.props
        const { pivotSelectedKey } = this.state

        if (match?.params?.companyId !== prevProps?.match?.params?.companyId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(command.find(x => x.key === 'edit') ? this.commandRead : this.commandEdit)
        }
        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.companiesHandlerRemoveById?.cancel()
        this.companiesHandlerGetById?.cancel()
        this.companiesHandlerUpsert?.cancel()
        this.setCompanyOperationalHandler?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, me } = this.props

        const breadcrumb = me.profileId === EProfile.SuperAdmin ? [
            { text: this.tKey('companies'), key: 'company', href: '/companies' },
            {
                text: `${this.tKey('company')} ${match?.params?.companyId ? `n°${match?.params?.companyId}` : this.tKey('new')}`,
                key: 'one-company',
                isCurrentItem: true,
            }] : []

        setBreadcrumb(breadcrumb)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, companiesHandler, setMessageBar, me,
        } = this.props

        const { pivotSelectedKey } = this.state

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
                isVisible: pivotSelectedKey === 'Data',
            },
        ].filter(x => x.isVisible)

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorCompany(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.companyId,
                isVisible: pivotSelectedKey === 'Data',
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
                isVisible: pivotSelectedKey === 'Data',
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteCompany'),
                        subTitle: this.tKey('sureDeleteCompany'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.companiesHandlerRemoveById = companiesHandler.removeById(match?.params?.companyId)
                                    await this.companiesHandlerRemoveById.fetch()
                                    history.push('/companies')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.companyId,
                isVisible: pivotSelectedKey === 'Data' && me.profileId === EProfile.SuperAdmin,
            },
        ].filter(x => x.isVisible)
    }

    /**
     * Set company operational
     * @param {number} companyId companyId
     */
    setCompanyOperational(companyId) {
        const {
            companiesHandler,
        } = this.props

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.setCompanyOperationalHandler = companiesHandler.setIsOperational(companyId)
                const company = await this.setCompanyOperationalHandler.fetch()
                this.setState({
                    item: company,
                    iniItem: company,
                    status: Status.RESOLVED,
                    errorField: new ErrorCompany(),
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, companiesHandler, setMessageBar, me: { profileId, companyId: userCompanyId },
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.companyId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.company) // If object came from history push with a create
                    this.setState({
                        item: location.state?.company,
                        iniItem: location.state?.company,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        const companyId = profileId === EProfile.Admin ? userCompanyId : match?.params?.companyId
                        this.companiesHandlerGetById = companiesHandler.getById(companyId)
                        const company = await this.companiesHandlerGetById.fetch()
                        this.setState({
                            item: company,
                            iniItem: company,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Company(),
                    iniItem: new Company(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, companiesHandler, filesHandler, match, setMessageBar,
        } = this.props
        const { item, tempLogo } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.companiesHandlerUpsert = companiesHandler.upsert(item, match?.params?.companyId)
                const company = await this.companiesHandlerUpsert.fetch()

                if (tempLogo) {
                    setMessageBar({ isDisplayed: false })
                    this.handlerUploadFile = filesHandler.uploadFile(
                        company?.companyId,
                        EFileFolder.CompanyLogo,
                        tempLogo,
                    )
                    company.logo = await this.handlerUploadFile.fetch()
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' })
                }
                this.setState({
                    item: company,
                    iniItem: company,
                    status: Status.RESOLVED,
                    errorField: new ErrorCompany(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.companyId)
                    history.replace(`/companies/${company.companyId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorCompany>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            filesHandler, addressesHandler, param, setModal, companiesHandler, me,
            deliveryTypesHandler, supportContactsHandler, setMessageBar, lang, logisticConstraintsHandler,
        } = this.props
        const {
            item, status, isReadOnly, errorField, tempLogo, pivotSelectedKey,
        } = this.state

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main>
                <Pivot
                    styles={{ itemContainer: { paddingTop: '20px' } }}
                    selectedKey={pivotSelectedKey}
                    onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                >
                    <PivotItem
                        headerText={this.tKey('company')}
                        itemIcon="PageData"
                        itemKey="Data"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >

                        <form
                            onSubmit={ev => {
                                ev.preventDefault()
                                this.upsert()
                            }}
                        >
                            <Card
                                title={this.tKey('information')}
                                iconName="PageData"
                                headerComponent={(
                                    <DefaultButton
                                        text={this.tKey('status')}
                                        iconProps={{
                                            iconName: item.isOperational ? 'CheckMark' : 'Cancel',
                                            styles: { root: { color: item.isOperational ? '#0b6a0b' : '#a4262c' } },
                                        }}
                                        title={item.isOperational ? this.tKey('setCompanyNonOperational') : this.tKey('setCompanyOperational')}
                                        onClick={() => {
                                            this.setCompanyOperational(item.companyId)
                                        }}
                                    />
                                )}
                            >
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label={this.tKey('name')}
                                            placeholder={this.tKey('name')}
                                            value={item.name}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, name: newVal } })}
                                            errorMessage={this.tObj(this.parseJson(errorField.name))}
                                            required={!isReadOnly}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label={this.tKey('exportFileFormat')}
                                            placeholder={this.tKey('exportFileFormat')}
                                            value={item.exportFileFormat ? item.exportFileFormat : ''}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, exportFileFormat: newVal } })}
                                            errorMessage={this.tObj(this.parseJson(errorField.exportFileFormat))}
                                            required={!isReadOnly}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label={this.tKey('version')}
                                            placeholder={this.tKey('version')}
                                            value={item.exportFileVersion ? item.exportFileVersion : ''}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, exportFileVersion: newVal } })}
                                            errorMessage={this.tObj(this.parseJson(errorField.exportFileVersion))}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label={this.tKey('timeOrder')}
                                            placeholder={this.tKey('timeOrder')}
                                            value={item.timeOrder ? item.timeOrder : ''}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            errorMessage={this.tObj(parseJson(errorField.timeOrder[0]))}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, timeOrder: newVal } })}
                                            type="time"
                                            required={!isReadOnly}
                                        />
                                    </Columns.Column>
                                    {item.companyId === ECompany.Pineau && (
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label={this.tKey('orderDay')}
                                                placeholder={isReadOnly ? '' : this.tKey('select')}
                                                readOnly={isReadOnly}
                                                options={param.daysOfTheWeek?.map(x => ({
                                                    key: x.key,
                                                    text: this.tObj(this.parseJson(x.text)),
                                                }))}
                                                selectedKey={item.dayOfTheWeekId}
                                                onChange={(ev, option) => {
                                                    ev.preventDefault()
                                                    this.setState({ item: { ...item, dayOfTheWeekId: option.key } })
                                                }}
                                                required={!isReadOnly}
                                                errorMessage={this.tObj(this.parseJson(errorField.dayOfTheWeekId[0]))}
                                            />
                                        </Columns.Column>
                                    )}
                                </Columns>
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        {item.companyId
                                            ? (
                                                <PreviewFileInput
                                                    handler={filesHandler}
                                                    fileFolderId={EFileFolder.CompanyLogo}
                                                    entityId={item.companyId}
                                                    isRequired={!isReadOnly}
                                                    isReadOnly={isReadOnly}
                                                    file={item.logo}
                                                    updateItem={file => this.setState({ item: { ...item, logo: file } })}
                                                />
                                            )
                                            : (
                                                <FileInput
                                                    label={this.tKey('logo')}
                                                    isReadOnly={false}
                                                    isDisabled={status === Status.PENDING}
                                                    fileName={tempLogo?.name}
                                                    tooltipContent=""
                                                    onDownload={() => null}
                                                    onUpload={file => Promise.resolve(this.setState({ tempLogo: file }))}
                                                    onDelete={() => Promise.resolve(this.setState({ tempLogo: null }))}
                                                />
                                            )}
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label={this.tKey('roles')}
                                            required={!isReadOnly}
                                            readOnly={isReadOnly}
                                            options={param.userProfiles
                                                .filter(x => x.key !== EProfile.SuperAdmin)
                                                .map(x => ({ ...x, text: this.tObj(parseJson(x.text)) }))}
                                            selectedKey={item.roleIds}
                                            onChange={(ev, option) => {
                                                this.setState({
                                                    item: {
                                                        ...item,
                                                        roleIds: this.getUpdatedList(item.roleIds, option),
                                                    },
                                                })
                                            }}
                                            errorMessage={this.tObj(this.parseJson(errorField.roleIds))}
                                            multiSelect
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label={this.tKey('intermediateWorkflowSteps')}
                                            readOnly={isReadOnly}
                                            options={param.workflowSteps
                                                .filter(x => [EWorkflowStep.AddressValidation, EWorkflowStep.OperatorsValidation].includes(+x.key))
                                                .map(x => ({ ...x, text: this.tObj(parseJson(x.text)) }))}
                                            selectedKey={item.companyRule?.companyRuleWorkflowStepIds}
                                            // @ts-ignore
                                            onChange={(ev, option) => {
                                                this.setState({
                                                    item: {
                                                        ...item,
                                                        companyRule: {
                                                            ...item.companyRule,
                                                            // eslint-disable-next-line max-len
                                                            companyRuleWorkflowStepIds: this.getUpdatedList(item.companyRule.companyRuleWorkflowStepIds, option),
                                                        },
                                                    },
                                                })
                                            }}
                                            errorMessage={this.tObj(this.parseJson(errorField.companyRule.companyRuleWorkflowStepIds))}
                                            multiSelect
                                        />
                                    </Columns.Column>

                                </Columns>
                            </Card>
                            <br />
                            <Card
                                title={this.tKey('specifications')}
                                iconName="PageData"
                            >
                                <Columns>
                                    <Columns.Column size="half">
                                        <Toggle
                                            label={this.tKey('areAddressTimeSlotsMandatory')}
                                            onText={this.tKey('yes')}
                                            offText={this.tKey('no')}
                                            checked={item.companyRule.areAddressTimeSlotsMandatory}
                                            onChange={(_ev, checked) => this.setState({
                                                item: { ...item, companyRule: { ...item.companyRule, areAddressTimeSlotsMandatory: checked } },
                                            })}
                                            disabled={isReadOnly}
                                        />
                                    </Columns.Column>
                                </Columns>
                            </Card>
                            <br />
                            <Card
                                title={this.tKey('fileSettings')}
                                iconName="DocumentManagement"
                            >
                                {item.fileCategories.map((fileCategory, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <React.Fragment key={`${fileCategory.fileCategoryId}_${i}`}>
                                        <Columns>
                                            <Columns.Column size="one-quarter">
                                                <TextField
                                                    label={this.tKey('nameEn')}
                                                    placeholder={this.tKey('nameEn')}
                                                    value={parseJson(fileCategory.name)?.en || ''}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    errorMessage={this.tObj(this.parseJson(errorField.name))}
                                                    // @ts-ignore
                                                    onChange={(ev, newVal) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            name: JSON.stringify({ ...parseJson(fileCategory.name), en: newVal }),
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>
                                            <Columns.Column size="one-quarter">
                                                <TextField
                                                    label={this.tKey('nameFr')}
                                                    placeholder={this.tKey('nameFr')}
                                                    value={parseJson(fileCategory.name)?.fr || ''}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    errorMessage={this.tObj(this.parseJson(errorField.name))}
                                                    // @ts-ignore
                                                    onChange={(ev, newVal) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            name: JSON.stringify({ ...parseJson(fileCategory.name), fr: newVal }),
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>
                                            <Columns.Column size="one-quarter">
                                                <TextField
                                                    label={this.tKey('nameNl')}
                                                    placeholder={this.tKey('nameNl')}
                                                    value={parseJson(fileCategory.name)?.nl || ''}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    errorMessage={this.tObj(this.parseJson(errorField.name))}
                                                    // @ts-ignore
                                                    onChange={(ev, newVal) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            name: JSON.stringify({ ...parseJson(fileCategory.name), nl: newVal }),
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('xmlCode')}
                                                    placeholder={this.tKey('xmlCode')}
                                                    readOnly={isReadOnly}
                                                    options={param.fileCategoryXmlCodes?.map(x => ({
                                                        key: x.key,
                                                        text: this.tObj(this.parseJson(x.text)),
                                                    }))}
                                                    selectedKey={item.fileCategories[i].fileCategoryXmlCodeId}
                                                    onChange={(ev, option) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            fileCategoryXmlCodeId: option.key,
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>

                                        </Columns>
                                        <Columns>
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('countriesMandatory')}
                                                    placeholder={this.tKey('countriesMandatory')}
                                                    readOnly={isReadOnly}
                                                    options={param.countries?.map(x => ({
                                                        key: x.key,
                                                        text: this.tObj(parseJson(x.text)),
                                                        disabled: fileCategory.optionalCountriesId?.some(y => y === x.key),
                                                    })).sort((a, b) => a.text.localeCompare(b.text))}
                                                    multiSelect
                                                    selectedKey={fileCategory.requiredCountriesId ?? []}
                                                    // @ts-ignore
                                                    onChange={(ev, option) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            requiredCountriesId: this.getUpdatedList(fileCategory.requiredCountriesId, option),
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                />
                                            </Columns.Column>
                                            {/* <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('countriesOptionnal')}
                                                    placeholder={this.tKey('countriesOptionnal')}
                                                    readOnly={isReadOnly}
                                                    options={param.countries?.map(x => ({
                                                        key: x.key,
                                                        text: this.tObj(parseJson(x.text)),
                                                        disabled: fileCategory.requiredCountriesId?.some(y => y === x.key),
                                                    })).sort((a, b) => a.text.localeCompare(b.text))}
                                                    multiSelect
                                                    selectedKey={fileCategory.optionalCountriesId ?? []}
                                                    // @ts-ignore
                                                    onChange={(ev, option) => {
                                                        const newFileCategories = [...item.fileCategories]
                                                        newFileCategories[i] = {
                                                            ...item.fileCategories[i],
                                                            optionalCountriesId: this.getUpdatedList(fileCategory.optionalCountriesId, option),
                                                        }
                                                        this.setState({ item: { ...item, fileCategories: newFileCategories } })
                                                    }}
                                                />
                                            </Columns.Column> */}
                                            <Columns.Column size="one-quarter" />
                                            <Columns.Column size="one-quarter" />
                                            <Columns.Column size="one-quarter">
                                                <Label>&#8203;</Label>
                                                <DefaultButton
                                                    text={this.tKey('delete')}
                                                    iconProps={{ iconName: 'Delete' }}
                                                    onClick={() => this.setState({
                                                        item: {
                                                            ...item, fileCategories: item.fileCategories.filter((_, y) => y !== i),
                                                        },
                                                    })}
                                                    disabled={isReadOnly}
                                                />
                                            </Columns.Column>
                                        </Columns>
                                        {i !== item.fileCategories.length - 1 && <hr />}
                                    </React.Fragment>
                                ))}
                                <DefaultButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => this.setState({ item: { ...item, fileCategories: item.fileCategories.concat([new FileCategory()]) } })}
                                    text={this.tKey('add')}
                                    disabled={isReadOnly}
                                />
                            </Card>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                                type="submit"
                                style={{ display: 'none' }}
                                ref={this.submitInput}
                                tabIndex={-1}
                            />
                        </form>
                    </PivotItem>
                    <PivotItem
                        headerText={this.tKey('platforms')}
                        itemIcon="MapPin"
                        itemKey="Address"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotAddresses
                            lang={lang}
                            items={item.addresses}
                            addressesHandler={addressesHandler}
                            indexes={{ companyId: item.companyId, clientId: null }}
                            param={param}
                            companyRules={item.companyRule}
                            setModal={setModal}
                            setMessageBar={setMessageBar}
                            me={me}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText={this.tKey('contacts')}
                        itemIcon="Contact"
                        itemKey="Contacts"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <SupportContacts
                            supportContacts={item.supportContacts}
                            lang={lang}
                            canEdit
                            supportContactsHandler={supportContactsHandler}
                            defaultData={{ companyId: item.companyId }}
                            supportContactTypes={param.supportContactTypes}
                            onError={() => setMessageBar({ isDisplayed: false })}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText={this.tKey('accessibilities')}
                        itemIcon="PageData"
                        itemKey="accessibilities"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotAccessibilities
                            lang={lang}
                            companiesHandler={companiesHandler}
                            companyId={item.companyId}
                            setModal={setModal}
                            accessibilities={item.accessibilities}
                            onChange={(accessibility, isDeletion = false) => {
                                const newItems = [...item.accessibilities]
                                const currIndex = newItems.findIndex(x => x.accessibilityId === accessibility.accessibilityId)

                                if (currIndex > -1)
                                    if (isDeletion)
                                        newItems.splice(currIndex, 1)
                                    else
                                        newItems[currIndex] = accessibility
                                else
                                    newItems.push(accessibility)

                                this.setState({
                                    item: {
                                        ...item,
                                        accessibilities: newItems,
                                    },
                                })
                            }}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText={this.tKey('logisticConstraints')}
                        itemIcon="PageData"
                        itemKey="logisticConstraints"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotLogisticConstraints
                            lang={lang}
                            logisticConstraints={item.logisticConstraints}
                            logisticConstraintsHandler={logisticConstraintsHandler}
                            companyId={item.companyId}
                            onChange={(logisticConstraint, isDeletion) => {
                                const newItems = [...item.logisticConstraints]
                                const currIndex = newItems.findIndex(x => x.logisticConstraintId === logisticConstraint.logisticConstraintId)

                                if (currIndex > -1)
                                    if (isDeletion)
                                        newItems.splice(currIndex, 1)
                                    else
                                        newItems[currIndex] = logisticConstraint
                                else
                                    newItems.push(logisticConstraint)

                                this.setState({
                                    item: {
                                        ...item,
                                        logisticConstraints: newItems,
                                    },
                                })
                            }}
                        />
                    </PivotItem>
                    {item?.companyId !== ECompany.JetFreeze && (
                        <PivotItem
                            headerText={this.tKey('deliveryTypes')}
                            itemIcon="PageData"
                            itemKey="deliveryTypes"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <PivotdeliveryTypes
                                lang={lang}
                                deliveryTypes={item.deliveryTypes}
                                deliveryTypesHandler={deliveryTypesHandler}
                                companyId={item.companyId}
                                onChange={(deliveryType, isDeletion) => {
                                    const newItems = [...item.deliveryTypes]
                                    const currIndex = newItems.findIndex(x => x.deliveryTypeId === deliveryType.deliveryTypeId)

                                    if (currIndex > -1)
                                        if (isDeletion)
                                            newItems.splice(currIndex, 1)
                                        else
                                            newItems[currIndex] = deliveryType
                                    else
                                        newItems.push(deliveryType)

                                    this.setState({
                                        item: {
                                            ...item,
                                            deliveryTypes: newItems,
                                        },
                                    })
                                }}
                            />
                        </PivotItem>
                    )}
                    {item?.companyId === ECompany.JetFreeze && (
                        <PivotItem
                            headerText={this.tKey('departments')}
                            itemIcon="PageData"
                            itemKey="departments"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <PivotDepartments
                                lang={lang}
                                companiesHandler={companiesHandler}
                                companyId={item.companyId}
                                param={param}
                                setModal={setModal}
                            />
                        </PivotItem>
                    )}
                    {item?.companyId === ECompany.JetFreeze && (
                        <PivotItem
                            headerText={this.tKey('deliveryTimes')}
                            itemIcon="PageData"
                            itemKey="deliveryTimes"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <PivotDeliveryTimes
                                lang={lang}
                                companiesHandler={companiesHandler}
                                companyId={item.companyId}
                                setModal={setModal}
                            />
                        </PivotItem>
                    )}
                    <PivotItem
                        headerText={this.tKey('operatingCenters')}
                        itemIcon="PageData"
                        itemKey="operatingCenters"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotOperatingCenters
                            lang={lang}
                            companiesHandler={companiesHandler}
                            filesHandler={filesHandler}
                            companyId={item.companyId}
                            setModal={setModal}
                        />
                    </PivotItem>
                </Pivot>
            </main>
        )
    }
}

Idcompanies.prototype.getUpdatedList = getUpdatedList
Idcompanies.prototype.isValidDate = isValidDate
Idcompanies.prototype.tKey = tKey
Idcompanies.prototype.tObj = tObj
Idcompanies.prototype.parseJson = parseJson
