import React from 'react'
import UsersHandler from 'requests/handlers/usersHandler'
import ClientAccountsHandler from 'requests/handlers/clientAccountsHandler'
import User from 'requests/objects/user'
import ApiHandler from 'requests/apiHandler'
import ParamsHandler from 'requests/handlers/paramsHandler'
import Param from 'requests/objects/param'
import ClientsHandler from 'requests/handlers/clientsHandler'
import Client from 'requests/objects/client'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import Company from 'requests/objects/company'
import HomesHandler from 'requests/handlers/homesHandler'
import Home from 'requests/objects/home'
import HomeArticlesHandler from 'requests/handlers/homeArticlesHandler'
import FilesHandler from 'requests/handlers/filesHandler'
import AddressesHandler from 'requests/handlers/addressesHandler'
import HomeActualitiesHandler from 'requests/handlers/homeActualitiesHandler'
import HomeArticle from 'requests/objects/homeArticle'
import HomeActuality from 'requests/objects/homeActuality'
import OrdersHandler from 'requests/handlers/ordersHandler'
import TransportsHandler from 'requests/handlers/transportsHandler'
import Order from 'requests/objects/order'
import Address from 'requests/objects/address'
import Transport, { Invoice } from 'requests/objects/transport'
import InvoicesHandler from 'requests/handlers/invoicesHandler'
import DocumentsHandler from 'requests/handlers/documentsHandler'
import Document from 'requests/objects/document'
import SupportContactsHandler from 'requests/handlers/supportClientsHandler'
import SupportContact from 'requests/objects/supportContact'
import ToolsHandler from 'requests/handlers/toolsHandler'
import LogisticConstraintsHandler from 'requests/handlers/logisticConstraintsHandler'
import DeliveryTypesHandler from 'requests/handlers/deliveryTypesHandler'
import StocksHandler from 'requests/handlers/stocksHandler'
import SearchFiltersHandler from 'requests/handlers/searchFiltersHandler'

/**
 * @typedef {object} HandlersProps
 * @property {function(object):ApiHandler<any>} handler Function to get proper handler for a desired object
 * @property {UsersHandler} usersHandler User Handler
 * @property {ClientAccountsHandler} clientAccountsHandler Client Accounts Handler
 * @property {ClientsHandler} clientsHandler Clients Handler
 * @property {CompaniesHandler} companiesHandler Companies Handler
 * @property {ParamsHandler} paramsHandler Param Handler
 * @property {HomesHandler} homesHandler HomesHandler
 * @property {HomeArticlesHandler} homeArticlesHandler HomeArticlesHandler
 * @property {FilesHandler} filesHandler FilesHandler
 * @property {AddressesHandler} addressesHandler addressesHandler
 * @property {HomeActualitiesHandler} homeActualitiesHandler HomeActualitiesHandler
 * @property {OrdersHandler} ordersHandler ordersHandler
 * @property {TransportsHandler} transportsHandler transportsHandler
 * @property {InvoicesHandler} invoicesHandler transportsHandler
 * @property {DocumentsHandler} documentsHandler DocumentsHandler
 * @property {SupportContactsHandler} supportContactsHandler SupportContactsHandler
 * @property {ToolsHandler} toolsHandler toolsHandler
 * @property {LogisticConstraintsHandler} logisticConstraintsHandler logisticConstraintsHandler
 * @property {DeliveryTypesHandler} deliveryTypesHandler deliveryTypesHandler
 * @property {StocksHandler} stocksHandler stocksHandler
 * @property {SearchFiltersHandler} searchFiltersHandler searchFiltersHandler
 */

// eslint-disable-next-line jsdoc/require-returns
/**
 * With handlers hoc
 * @param {object} WrappedComponent Component to wrapp
 */
export default function withHandlers(WrappedComponent) {
    // eslint-disable-next-line react/display-name
    return class extends React.Component {
        constructor(props) {
            super(props)

            // Declare all handlers
            const usersHandler = new UsersHandler()
            const clientAccountsHandler = new ClientAccountsHandler()
            const clientsHandler = new ClientsHandler()
            const companiesHandler = new CompaniesHandler()
            const paramsHandler = new ParamsHandler()
            const homesHandler = new HomesHandler()
            const homeArticlesHandler = new HomeArticlesHandler()
            const filesHandler = new FilesHandler()
            const addressesHandler = new AddressesHandler()
            const homeActualitiesHandler = new HomeActualitiesHandler()
            const ordersHandler = new OrdersHandler()
            const transportsHandler = new TransportsHandler()
            const invoicesHandler = new InvoicesHandler()
            const documentsHandler = new DocumentsHandler()
            const supportContactsHandler = new SupportContactsHandler()
            const toolsHandler = new ToolsHandler()
            const logisticConstraintsHandler = new LogisticConstraintsHandler()
            const deliveryTypesHandler = new DeliveryTypesHandler()
            const stocksHandler = new StocksHandler()
            const searchFiltersHandler = new SearchFiltersHandler()

            /** @type {object} Store handlers in an object */
            this.handlers = {
                usersHandler,
                clientAccountsHandler,
                clientsHandler,
                companiesHandler,
                paramsHandler,
                homesHandler,
                homeArticlesHandler,
                filesHandler,
                addressesHandler,
                homeActualitiesHandler,
                ordersHandler,
                transportsHandler,
                invoicesHandler,
                documentsHandler,
                supportContactsHandler,
                toolsHandler,
                logisticConstraintsHandler,
                deliveryTypesHandler,
                stocksHandler,
                searchFiltersHandler,
            }

            /** @type {function(object):ApiHandler<any>} Function to get proper handler for a desired object */
            this.handler = obj => {
                switch (obj) {
                    case User:
                        return usersHandler
                    case ClientAccountsHandler:
                        return clientAccountsHandler
                    case Client:
                        return clientsHandler
                    case Company:
                        return companiesHandler
                    case Param:
                        return paramsHandler
                    case Home:
                        return homesHandler
                    case HomeArticle:
                        return homeArticlesHandler
                    case FilesHandler:
                        return filesHandler
                    case AddressesHandler:
                        return addressesHandler
                    case HomeActuality:
                        return homeActualitiesHandler
                    case Order:
                        return ordersHandler
                    case Transport:
                        return transportsHandler
                    case Address:
                        return addressesHandler
                    case Invoice:
                        return invoicesHandler
                    case Document:
                        return documentsHandler
                    case SupportContact:
                        return supportContactsHandler
                    case LogisticConstraintsHandler:
                        return logisticConstraintsHandler
                    case DeliveryTypesHandler:
                        return deliveryTypesHandler
                    // case DeliveryTimeParams:
                    //     return toolsHandler
                    case StocksHandler:
                        return stocksHandler
                    case SearchFiltersHandler:
                        return searchFiltersHandler
                    default:
                        return null
                }
            }
        }

        render() {
            return (
                <WrappedComponent
                    handler={this.handler}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.handlers}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                />
            )
        }
    }
}
