/* eslint-disable max-classes-per-file */

/**
 * Base Object
 */
export default class Base {
    /**
     * @param {object} data data
     * @param {string=} data.createdAt createdAt
     * @param {string=} data.updatedAt updatedAt
     */
    constructor({
        createdAt,
        updatedAt,
    } = {}) {
        this.createdAt = createdAt ? new Date(createdAt) : undefined
        this.updatedAt = updatedAt ? new Date(updatedAt) : undefined
    }
}

/**
 * Base Object used to bind error message
 */
export class ErrorBase {
    constructor() {
        this.createdAt = ''
        this.updatedAt = ''
    }
}
