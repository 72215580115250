// @ts-nocheck
import React, {
    PureComponent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { Navbar } from 'react-bulma-components'
import {
    DirectionalHint,
    getTheme,
    Icon, IconButton, Panel, TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import enhanceWithClickOutside from 'react-click-outside'
import { Link } from 'react-router-dom'
import User from 'requests/objects/user'
import { Langs } from 'types/translations'
import { tKey, tObj } from 'helpers/methods/translate'
import { ParamElement } from 'requests/objects/param'
import ECompany from 'types/companies/enums/company'
import EProfile from 'types/users/enums/profile'
import UsersHandler from 'requests/handlers/usersHandler'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import NotImplementedError from 'requests/errors/notImplementedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import FilesHandler from 'requests/handlers/filesHandler'
import useTranslate from 'helpers/hooks/useTranslate'
import ParamsHandler from 'requests/handlers/paramsHandler'
// eslint-disable-next-line import/named
import { PayloadParams, PayloadSignIn } from 'redux/slices/user'
import { flushSync } from 'react-dom'

const Header = enhanceWithClickOutside(
    /**
     * @typedef {object} HeaderProps
     * @property {import('pages/_layout').MenuElement[]} menu Menu to display
     * @property {User} me User informations
     * @property {object} param Constants
     * @property {function():void} signOut Sign out
     * @property {function():void} refresh Refresh app
     * @property {import('types/translations').LangsType} lang Lang
     * @property {ECompany} company company
     * @property {function(string):void} setLang Set lang
     * @property {Function=} setMe Set me
     * @property {UsersHandler} usersHandler usersHandler
     * @property {FilesHandler} filesHandler filesHandler
     * @property {ParamsHandler} paramsHandler paramsHandler
     * @property {function(PayloadParams):void} setParams setParams
     * @property {function(PayloadSignIn):void} signIn signIn
     * @augments {PureComponent<HeaderProps>}}
     */
    // eslint-disable-next-line react/display-name
    class extends PureComponent {
        constructor(props) {
            super(props)
            this.state = {
                /** @type {boolean} Is burger menu open on mobile */
                isActive: false,
                /** @type {boolean} Is Panel user open */
                isOpenUser: false,
            }

            this.tKey = tKey
            this.tObj = tObj
        }

        /**
         * Handle click outside component
         */
        handleClickOutside() {
            this.setState({ isActive: false })
        }

        /**
         * Render component
         * @returns {JSX.Element} Element
         */
        render() {
            const {
                menu, me, signOut, refresh, lang, setLang, setMe, signIn,
                param, usersHandler, filesHandler, paramsHandler, setParams,
            } = this.props
            const {
                isActive, isOpenUser,
            } = this.state

            return (
                <>
                    <Navbar
                        active={isActive}
                        transparent={false}
                    >
                        <Navbar.Brand>
                            <Navbar.Item
                                renderAs="a"
                                onClick={() => history.push('/')}
                            >
                                <HeaderLogo
                                    lang={lang}
                                    filesHandler={filesHandler}
                                    me={me}
                                />
                            </Navbar.Item>
                            {me.isClient && (
                                <Navbar.Item
                                    renderAs="a"
                                    style={{
                                        marginTop: '4px',
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translate(-50%, 0)',
                                    }}
                                    className="is-hidden-touch"
                                    onClick={() => history.push('/')}
                                >
                                    <MyHeaderLogo
                                        filesHandler={filesHandler}
                                        logoId={me.client.logoId}
                                        title={me.client.name}
                                    />
                                </Navbar.Item>
                            )}
                            {me.profileId === EProfile.SuperAdmin && (
                                <CompanySelector
                                    company={me.companyId}
                                    setMe={setMe}
                                    companies={param.companies}
                                    className="is-hidden-desktop"
                                    usersHandler={usersHandler}
                                    paramsHandler={paramsHandler}
                                    setParams={setParams}
                                />
                            )}
                            {
                                [
                                    EProfile.CustomOrderEntry,
                                    EProfile.CustomOrderTracking,
                                    EProfile.CustomerAdmin,
                                ].includes(me.profileId) && me.clientIds?.length > 1 && (
                                    <ClientSelector
                                        client={me.clientId}
                                        setMe={setMe}
                                        clients={param.clients.filter(x => me.clientIds.includes(+x.key))}
                                        usersHandler={usersHandler}
                                        paramsHandler={paramsHandler}
                                        setParams={setParams}
                                        className="is-hidden-desktop"
                                    />
                                )
                            }
                            <LangSelector
                                lang={lang}
                                setLang={setLang}
                                className="is-hidden-desktop"
                            />
                            <Navbar.Burger onClick={() => this.setState({ isActive: !isActive })} />
                        </Navbar.Brand>
                        <Navbar.Menu>
                            <Navbar.Container className="is-hidden-desktop">
                                {
                                    menu.map((x, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Navbar.Item key={i}>
                                            {
                                                x.name
                                                && (
                                                    <Navbar.Link arrowless>
                                                        {x.name}
                                                    </Navbar.Link>
                                                )
                                            }
                                            <Navbar.Dropdown>
                                                {
                                                    x?.links.map((y, j) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <React.Fragment key={`${i}_${j}`}>
                                                            <Link
                                                                className="navbar-item"
                                                                to={y.key}
                                                                onClick={() => this.setState({ isActive: false })}
                                                            >
                                                                <Icon iconName={y.icon} />
                                                                <span>
                                                                    {' '}
                                                                    {y.title}
                                                                </span>
                                                            </Link>
                                                            {
                                                                y?.links?.length
                                                                && y?.links.map((z, k) => (
                                                                    <Link
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        key={`${i}_${j}_${k}`}
                                                                        className="navbar-item"
                                                                        to={z.key}
                                                                        onClick={() => this.setState({ isActive: false })}
                                                                    >
                                                                        <Icon iconName={z.icon} />
                                                                        <span>
                                                                            {' '}
                                                                            {z.title}
                                                                        </span>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Navbar.Dropdown>
                                        </Navbar.Item>
                                    ))
                                }
                                <Navbar.Item>
                                    <Navbar.Link arrowless>
                                        {this.tKey('other')}
                                    </Navbar.Link>
                                    <Navbar.Dropdown>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isActive: false }, () => refresh())}
                                        >
                                            <Icon iconName="Refresh" />
                                            {' '}
                                            {this.tKey('refresh')}
                                        </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isOpenUser: true })}
                                        >
                                            <Icon iconName="Contact" />
                                            {' '}
                                            {this.tKey('user')}
                                        </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => signOut()}
                                        >
                                            <Icon iconName="SignOut" />
                                            {' '}
                                            {this.tKey('login')}
                                        </Navbar.Item>
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                            </Navbar.Container>
                            <Navbar.Container
                                align="right"
                                className="is-hidden-touch"
                            >
                                {
                                    (([
                                        EProfile.CustomOrderEntry,
                                        EProfile.CustomOrderTracking,
                                        EProfile.CustomerAdmin,
                                    ].includes(me.profileId) && me.clientIds?.length > 1) || (me.profileId === EProfile.SuperAdmin)) && (
                                        <Navbar.Item
                                            className="is-tab"
                                        >
                                            {
                                                me.profileId === EProfile.SuperAdmin ? (
                                                    <CompanySelector
                                                        company={me.companyId}
                                                        setMe={setMe}
                                                        companies={param.companies}
                                                        usersHandler={usersHandler}
                                                        paramsHandler={paramsHandler}
                                                        setParams={setParams}
                                                        signIn={signIn}
                                                    />
                                                ) : (
                                                    <ClientSelector
                                                        client={me.clientId}
                                                        setMe={setMe}
                                                        clients={param.clients.filter(x => me.clientIds.includes(+x.key))}
                                                        usersHandler={usersHandler}
                                                        paramsHandler={paramsHandler}
                                                        setParams={setParams}
                                                    />
                                                )
                                            }
                                        </Navbar.Item>
                                    )
                                }
                                <Navbar.Item
                                    className="is-tab"
                                >
                                    <LangSelector
                                        lang={lang}
                                        setLang={setLang}
                                    />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => refresh()}
                                >
                                    <Icon iconName="Refresh" />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => this.setState({ isOpenUser: true })}
                                >
                                    <Icon iconName="Contact" />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => signOut()}
                                >
                                    <Icon iconName="SignOut" />
                                </Navbar.Item>
                            </Navbar.Container>
                        </Navbar.Menu>
                    </Navbar>
                    <Panel
                        className="panel-user"
                        headerText={this.tKey('yourAccount')}
                        isLightDismiss
                        isOpen={isOpenUser}
                        onDismiss={() => this.setState({ isOpenUser: false })}
                        closeButtonAriaLabel="Close"
                    >
                        <br />
                        <TextField
                            label={this.tKey('username')}
                            value={`${me?.lastname ?? ''} ${me?.firstname ?? ''}`}
                            borderless
                            readOnly
                        />
                    </Panel>
                </>
            )
        }
    },
)
const defaultLogo = require('assets/img/logo.png').toString()

/**
 * @param {object} props Props
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {User} props.me me
 * @param {object} props.lang lang
 * @returns {JSX.Element} Returns
 */
function HeaderLogo({
    filesHandler, me, lang,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [companyLogoSrc, setCompanyLogoSrc] = useState(null)
    const { tKey: translateKey } = useTranslate({ lang })
    const [oldLogoId, setOldLogoId] = useState(me.company.logoId)

    const [logoId] = useMemo(() => [me.company.logoId], [me.company.logoId])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const getImgFilesHandler = useRef()

    const fetchCompanyLogo = useCallback(async () => {
        if (status === Status.PENDING)
            return
        setStatus(Status.PENDING)
        getImgFilesHandler.current = filesHandler.getPublicFileById(logoId, false)
        const img = await getImgFilesHandler.current.fetch()
        if (img) {
            setCompanyLogoSrc(window.URL.createObjectURL(img))
            setOldLogoId(logoId)
        }
        setStatus(Status.RESOLVED)
    }, [filesHandler, status, logoId])

    useEffect(() => {
        if ((!companyLogoSrc && logoId) || (logoId && logoId !== oldLogoId)) {
            fetchCompanyLogo()
        } else if (!logoId && companyLogoSrc && oldLogoId) {
            setOldLogoId(null)
            setCompanyLogoSrc(null)
        }
    }, [logoId, companyLogoSrc, fetchCompanyLogo, oldLogoId])

    useEffect(() => () => {
        getImgFilesHandler.current?.cancel()
    }, [])

    const appName = useMemo(() => `${translateKey('appName')} - ${me.company.name.toLocaleUpperCase()}`, [me.company.name, translateKey])

    return (
        <>
            <img
                src={status === Status.PENDING || !companyLogoSrc ? defaultLogo : companyLogoSrc}
                alt={appName}
            />
            <span
                className="is-hidden-touch"
            >
                {' '}
                {appName}
            </span>
        </>
    )
}

/**
 * @param {object} props Props
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {number} props.logoId logoId
 * @param {string} props.title title
 * @returns {JSX.Element} Returns
 */
function MyHeaderLogo({
    filesHandler, logoId, title,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [logoSrc, setLogoSrc] = useState(null)
    const [currentLogoId, setCurrentLogoId] = useState(logoId)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const getImgFilesHandler = useRef()

    const fetchLogo = useCallback(async () => {
        if (status === Status.PENDING)
            return
        setStatus(Status.PENDING)
        getImgFilesHandler.current = filesHandler.getPublicFileById(logoId, false)
        const img = await getImgFilesHandler.current.fetch()
        if (img) {
            setLogoSrc(window.URL.createObjectURL(img))
            setCurrentLogoId(logoId)
        }
        setStatus(Status.RESOLVED)
    }, [status, filesHandler, logoId])

    useEffect(() => {
        if ((!logoSrc && logoId) || (logoId && logoId !== currentLogoId)) {
            fetchLogo()
        } else if (!logoId && currentLogoId && logoSrc) {
            setCurrentLogoId(null)
            setLogoSrc(null)
        }
    }, [logoId, logoSrc, currentLogoId, fetchLogo])

    useEffect(() => () => {
        setCurrentLogoId(null)
        setLogoSrc(null)
        getImgFilesHandler.current?.cancel()
    }, [])

    return (
        <>
            <img
                src={status === Status.PENDING || !logoSrc ? defaultLogo : logoSrc}
                alt={title}
            />
            <span>
                {' '}
                {title}
            </span>
        </>
    )
}

/**
 * @param {object} props Props
 * @param {Function=} props.setMe Set company
 * @param {ECompany} props.company company
 * @param {ParamElement[]} props.companies companies
 * @param {UsersHandler} props.usersHandler usersHandler
 * @param {string=} props.className className\
 * @param {ParamsHandler=} props.paramsHandler paramsHandler
 * @param {function(PayloadParams):void=} props.setParams setParams
 * @param {function(PayloadSignIn):void=} props.signIn signIn
 * @returns {JSX.Element} Returns
 */
function CompanySelector({
    setMe, company, companies, usersHandler, className, paramsHandler, setParams, signIn,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<User>>} */
    const setCompanyHandler = useRef()
    const getAllParamsHandler = useRef()

    const switchCompany = useCallback(async companyId => {
        try {
            setStatus(Status.PENDING)
            setCompanyHandler.current = usersHandler.switchCompany(companyId)
            const { refreshToken, accessToken, ...user } = await setCompanyHandler.current.fetch()
            getAllParamsHandler.current = paramsHandler.getAll()
            const params = await getAllParamsHandler.current.fetch()
            flushSync(() => {
                signIn({ accessToken, refreshToken })
                setMe({ me: user })
                setParams({ params })
            })
            history.push('/')
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [usersHandler, paramsHandler, setMe, signIn, setParams])

    useEffect(() => () => {
        setCompanyHandler.current?.cancel()
        getAllParamsHandler.current?.cancel()
    }, [])

    return (
        <IconButton
            menuProps={{
                items: companies.map(x => ({
                    key: `${x.key}`,
                    text: x.text?.toUpperCase(),
                    style: {
                        color: company === +x.key ? getTheme().palette.themePrimary : undefined,
                        fontWeight: company === +x.key ? 'bold' : undefined,
                    },
                    disabled: status === Status.PENDING,
                })),
                directionalHint: DirectionalHint.bottomRightEdge,
                onItemClick: (ev, item) => {
                    switchCompany(item.key)
                },
                isBeakVisible: true,
            }}
            iconProps={{ iconName: 'CompanyDirectory' }}
            className={`company-selector ${className}`}
        />
    )
}

/**
 * @param {object} props Props
 * @param {Function=} props.setMe Set client
 * @param {number} props.client client
 * @param {ParamElement[]} props.clients clients
 * @param {UsersHandler} props.usersHandler usersHandler
 * @param {string=} props.className className
 * @param {ParamsHandler=} props.paramsHandler paramsHandler
 * @param {function(PayloadParams):void=} props.setParams setParams
 * @returns {JSX.Element} Returns
 */
function ClientSelector({
    setMe, client, clients, usersHandler, className, paramsHandler, setParams,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<User>>} */
    const setClientHandler = useRef()
    const switchClient = useCallback(async clientId => {
        try {
            setStatus(Status.PENDING)
            setClientHandler.current = usersHandler.switchClient(clientId)
            const user = await setClientHandler.current.fetch()
            const params = await paramsHandler.getAll().fetch()
            setParams({ params })
            setMe({ me: user })
            setStatus(Status.RESOLVED)
            history.push('/')
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [usersHandler, paramsHandler, setParams, setMe])

    useEffect(() => () => {
        setClientHandler.current?.cancel()
    }, [setClientHandler])

    return (
        <IconButton
            menuProps={{
                items: clients.map(x => ({
                    key: `${x.key}`,
                    text: x.text?.toUpperCase(),
                    style: {
                        color: client === +x.key ? getTheme().palette.themePrimary : undefined,
                        fontWeight: client === +x.key ? 'bold' : undefined,
                    },
                    disabled: status === Status.PENDING,
                })),
                directionalHint: DirectionalHint.bottomRightEdge,
                onItemClick: (ev, item) => {
                    switchClient(item.key)
                },
                isBeakVisible: true,
            }}
            iconProps={{ iconName: 'CompanyDirectory' }}
            className={`company-selector ${className}`}
        />
    )
}

/**
 * @param {object} props Props
 * @param {(key: string) => void} props.setLang Set lang
 * @param {string} props.lang Lang
 * @param {string=} props.className className
 * @returns {JSX.Element} Returns
 */
export function LangSelector({ setLang, lang, className }) {
    return (
        <IconButton
            menuProps={{
                items: Langs.map(x => ({
                    key: x,
                    text: x?.toUpperCase(),
                    style: {
                        color: lang === x ? getTheme().palette.themePrimary : undefined,
                        fontWeight: lang === x ? 'bold' : undefined,
                    },
                })),
                directionalHint: DirectionalHint.bottomRightEdge,
                onItemClick: (ev, item) => setLang(item.key),
                isBeakVisible: true,
            }}
            iconProps={{ iconName: 'Globe' }}
            className={`lang-selector ${className}`}
        />
    )
}

export default Header
