import Param from 'requests/objects/param'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import NotImplementedError from 'requests/errors/notImplementedError'
import { AxiosResponse } from 'axios'

/**
 * ParamsHandler
 * @augments {ApiHandler<Param, undefined>}
 */
export default class ParamsHandler extends ApiHandler {
    constructor() {
        super({ type: Param, errorType: undefined, key: 'params' })
    }

    /**
     * @override
     * @returns {RequestApi<Param | any>} Request
     */
    getAll() {
        const request = this.initFetchRequest({})

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * @returns {RequestApi<Date>} Request
     */
    getLastUpdate() {
        const request = this.initFetchRequest({ url: ['last-update'] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => (/** @type {AxiosResponse<any>} */(res).data.lastUpdate ? new Date(/** @type {AxiosResponse<any>} */(res).data.lastUpdate) : null))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    create() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    updateById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    removeById() {
        throw new NotImplementedError()
    }
}
