import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/orders/[id]/client/confirmation-step.module.scss'
import Card from 'components/containers/card'
import useTranslate from 'helpers/hooks/useTranslate'
import Order from 'requests/objects/order'
import {
    ConstrainMode,
    DefaultButton, TooltipHost,
    DetailsListLayoutMode, DirectionalHint,
    TooltipDelay, DetailsRow, Icon, IconButton, PrimaryButton, SelectionMode, ShimmeredDetailsList, Text, Spinner, SpinnerSize,
} from '@fluentui/react'
import Param from 'requests/objects/param'
import DeliveryData from 'requests/objects/order/deliveryData'
import parseJson from 'helpers/methods/parseJson'
import OrdersHandler from 'requests/handlers/ordersHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import TrackingLinkModal from 'components/pages/orders/[id]/client/deliveryStep/trackingLinkModal'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import useCompany from 'helpers/hooks/useCompany'
import classNames from 'classnames'
import Banner from 'components/containers/banner'
import EOrderStatus from 'types/orders/enums/orderStatus'
import ESupportContact from 'types/supportContact/enums/supportContact'
import Divider from 'components/visuals/divider'
import Status from 'types/status'
import LabelModal from 'components/pages/orders/[id]/client/deliveryStep/labelModal'
import EOrderType from 'types/orders/enums/orderType'
import User from 'requests/objects/user'

/**
 * ConfirmationStep
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Order} props.item Item
 * @param {(newIndex: number) => void} props.setDeliveryIndex setDeliveryIndex
 * @param {(newIndex: number) => void} props.setCurrentPageIndex setCurrentPageIndex
 * @param {Param} props.param param
 * @param {(order: Partial<Order>) => void} props.onChange onChange
 * @param {(arg0: import('redux/slices/common').PayloadModal) => void} props.setModal setModal
 * @param {(arg0: import('redux/slices/common').PayloadMessageBar) => void} props.setMessageBar setMessageBar
 * @param {OrdersHandler} props.ordersHandler ordersHandler
 * @param {boolean} props.userCanEdit userCanEdit
 * @param {User} props.me me
 * @returns {JSX.Element} Element
 */
export default function ConfirmationStep({
    lang, item, setDeliveryIndex, setCurrentPageIndex, param,
    onChange, setModal, setMessageBar, ordersHandler, userCanEdit, me,
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const { isRaud, isJetFreeze, isPineau } = useCompany({ companyId: me.companyId })

    const [deliveryDataId, setDeliveryDataId] = useState(null)
    const [orderHandlingId, setOrderHandlingId] = useState(null)
    const [isTrackingLinkModalVisible, setIsTrackingLinkModalVisible] = useState(false)
    const [isLabelModalVisible, setIsLabelModalVisible] = useState(false)
    const [summaryStatus, setSummaryStatus] = useState(Status.IDLE)

    const { deliveryDatas } = useMemo(() => item, [item])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryData>>} */
    const setNewDeliveryDataHandler = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Order>>} */
    const removeDeliveryDataHandler = useRef()

    /** Add a new delivery */
    // const printEtiquette = useCallback(async () => {
    //     try {
    //         setLabelStatus(Status.PENDING)
    //         setMessageBar({ isDisplayed: false })
    //         const blob = await printEtiquetteHandler.current.fetch()
    //         HandleBlob.download(blob, `${tKey('labels').toLowerCase()}.pdf`)
    //         setLabelStatus(Status.RESOLVED)
    //     } catch (error) {
    //         setLabelStatus(Status.REJECTED)
    //         switch (error?.constructor) {
    //             case CancelRequestError:
    //             case UnauthorizedError:
    //             case InvalidEntityError: break
    //             case NotImplementedError:
    //             default:
    //                 // eslint-disable-next-line no-console
    //                 console.error(error)
    //                 break
    //         }
    //     }
    // }, [setMessageBar, ordersHandler, item.orderId, tKey])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const printSummaryHandler = useRef()

    /** Add a new delivery */
    const printSummary = useCallback(async () => {
        try {
            setMessageBar({ isDisplayed: false })
            setSummaryStatus(Status.PENDING)
            printSummaryHandler.current = ordersHandler.printSummary(item.orderId)
            const blob = await printSummaryHandler.current.fetch()
            const fileName = `${tKey('summary')
                }-${item.orderId
                }-${item.client.clientTmsId
                }-${item.workflowHistorics.find(x => x.workflowStepId === item.currentWorkflowStepId).updatedAt.toLocaleDateString()
                }.pdf`
            HandleBlob.download(blob, fileName)
            setSummaryStatus(Status.RESOLVED)
        } catch (error) {
            setSummaryStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [setMessageBar, ordersHandler, item.orderId, item.client.clientTmsId, item.workflowHistorics, item.currentWorkflowStepId, tKey])

    /** Add a new delivery */
    const addNewDelivery = useCallback(async () => {
        try {
            setMessageBar({ isDisplayed: false })
            setNewDeliveryDataHandler.current = ordersHandler.setNewDeliveryData(item.orderId)
            const deliveryData = await setNewDeliveryDataHandler.current.fetch()
            onChange({
                deliveryDatas: [
                    ...item.deliveryDatas,
                    deliveryData,
                ],
            })
            setDeliveryIndex(item.deliveryDatas.length)
            setCurrentPageIndex(1)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }, [item.deliveryDatas, item.orderId, onChange, ordersHandler, setCurrentPageIndex, setDeliveryIndex, setMessageBar])

    /** Remove or  a delivery */
    const removeDelivery = useCallback(
        /**
         * @param {number} targetDeliveryDataId targetDeliveryDataId
         */
        async targetDeliveryDataId => {
            setModal({
                show: true,
                title: tKey('removeDelivery'),
                subTitle: tKey('sureRemoveDelivery'),
                callback: async () => {
                    try {
                        setMessageBar({ isDisplayed: false })
                        removeDeliveryDataHandler.current = ordersHandler.removeDeliveryData(item.orderId, targetDeliveryDataId)
                        const order = await removeDeliveryDataHandler.current.fetch()
                        onChange(order)
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error)
                    }
                },
            })
        }, [item.orderId, onChange, ordersHandler, setMessageBar, setModal, tKey],
    )

    /** Read or edit a delivery */
    const readOrEditDelivery = useCallback(
        /**
         * @param {number} targetDeliveryDataId targetDeliveryDataId
         */
        targetDeliveryDataId => {
            const index = item.deliveryDatas.findIndex(x => x.deliveryDataId === targetDeliveryDataId)
            if (index > -1) {
                setDeliveryIndex(item.deliveryDatas.findIndex(x => x.deliveryDataId === targetDeliveryDataId))
                setCurrentPageIndex(1)
            }
        }, [item.deliveryDatas, setCurrentPageIndex, setDeliveryIndex],
    )

    const pickupColumns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'ref',
            name: tKey('remittance'),
            fieldName: 'orderId',
            minWidth: 50,
            maxWidth: 90,
            isResizable: true,
        },
        {
            key: 'pickupAddress',
            name: tKey('pickupAddress'),
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            className: styles['delivery-address-data-item'],
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: order => (
                <>
                    <b>{order.pickupAddressData?.address?.label || ''}</b>
                    <span>
                        {order.pickupAddressData?.address?.street || ''}
                        {' '}
                        {order.pickupAddressData?.address?.zipCode || ''}
                        {' '}
                        {order.pickupAddressData?.address?.city || ''}
                        {' '}
                        {order.pickupAddressData?.address?.country?.code || ''}
                    </span>
                </>
            ),
        },
        {
            key: 'pickupDate',
            name: tKey('pickupDate'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            className: styles['delivery-address-data-item'],
            onRender: () => (item.pickupDateData?.pickupDate ? new Date(item.pickupDateData.pickupDate).toLocaleDateString() : ''),
        },
        {
            key: 'button',
            fieldName: 'button',
            minWidth: 250,
            maxWidth: 500,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: () => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className={styles['delivery-action-buttons']}>
                        <TooltipHost
                            content={tKey(userCanEdit ? 'editPickup' : 'seeMore')}
                            directionalHint={DirectionalHint.topCenter}
                            delay={TooltipDelay.long}
                            styles={{ root: { margin: '-6px 0' } }}
                        >
                            <IconButton
                                iconProps={{
                                    iconName: userCanEdit ? 'Edit' : 'ReadingMode',
                                }}
                                onClick={() => setCurrentPageIndex(0)}
                            />
                        </TooltipHost>
                        <IconButton
                            disabled
                            styles={{
                                root: { color: 'transparent', backgroundColor: 'transparent' },
                                rootDisabled: { margin: '-6px 0', color: 'transparent', backgroundColor: 'transparent' },
                            }}
                        />
                    </div>
                </div>
            ),
        },
    ]), [item.pickupDateData.pickupDate, setCurrentPageIndex, tKey, userCanEdit])

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'index',
            name: tKey('index'),
            fieldName: 'index',
            minWidth: 50,
            maxWidth: 90,
            isResizable: true,
        },
        {
            key: 'deliveryAddress',
            name: tKey('deliveryAddress'),
            fieldName: 'deliveryAddress',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            className: styles['delivery-address-data-item'],
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (
                <>
                    <b>{data.deliveryAddressData?.address?.label || ''}</b>
                    <span>
                        {data.deliveryAddressData?.address?.street || ''}
                        {' '}
                        {data.deliveryAddressData?.address?.zipCode || ''}
                        {' '}
                        {data.deliveryAddressData?.address?.city || ''}
                        {' '}
                        {data.deliveryAddressData?.address?.country?.code || ''}
                    </span>
                </>
            ),
        },
        {
            key: 'deliveryDate',
            name: tKey('deliveryDate'),
            fieldName: 'deliveryDate',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            className: styles['delivery-address-data-item'],
            onRender: data => (data.deliveryDateData?.wishedDateOfDelivery ? new Date(data.deliveryDateData.wishedDateOfDelivery).toLocaleDateString() : ''),
        },
        {
            key: 'content',
            name: tKey('merchandises'),
            fieldName: 'content',
            minWidth: 250,
            maxWidth: 500,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => {
                if (data.deliveryDataId && !data.orderHandlingId) {
                    const [
                        deliveryTooltipText,
                        deliveryIconName,
                        deliveryIconColor,
                    ] = (() => {
                        switch (data.generationStatus) {
                            case 'PENDING':
                                return ['deliverySent', 'CloudUpload', '']
                            case 'SUCCESS':
                                return ['deliveryConfirmed', 'CheckMark', 'green']
                            case 'ERROR':
                                return ['deliveryNotConfirmed', 'ErrorBadge', 'red']
                            default:
                                return ['deliveryWaiting', 'StatusCircleSync', '']
                        }
                    })()
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span
                                onClick={() => {
                                    setDeliveryDataId(prevSelectedOrderId => (prevSelectedOrderId !== data.deliveryDataId ? data.deliveryDataId : null))
                                    setOrderHandlingId(null)
                                }}
                                onKeyDown={() => null}
                                role="button"
                                tabIndex={0}
                                style={{
                                    cursor: 'pointer', display: 'block', margin: '-11px', padding: '11px',
                                }}
                            >
                                <Icon
                                    iconName={deliveryDataId !== data.deliveryDataId ? 'ChevronRight' : 'ChevronDown'}
                                />
                                {' '}
                                {data.deliveryContentData?.orderHandlings?.length ?? 0}
                                {' '}
                                (
                                {tKey('handlingUnit')}
                                )
                            </span>
                            <div className={styles['delivery-action-buttons']}>
                                {!data.hasRequiredFile && (
                                    <TooltipHost
                                        content={tKey('hasMissingFile')}
                                        directionalHint={DirectionalHint.leftCenter}
                                        delay={TooltipDelay.zero}
                                    >
                                        <div style={{ pointerEvents: 'none' }}>
                                            <IconButton
                                                iconProps={{ iconName: 'FileBug', style: { color: '#f14668' } }}
                                                styles={{ root: { margin: '-6px 0' } }}
                                            />
                                        </div>
                                    </TooltipHost>
                                )}
                                <TooltipHost
                                    content={tKey(userCanEdit ? 'editDelivery' : 'seeMore')}
                                    directionalHint={DirectionalHint.topCenter}
                                    delay={TooltipDelay.long}
                                    styles={{ root: { margin: '-6px 0' } }}
                                >
                                    <IconButton
                                        iconProps={{
                                            iconName: userCanEdit ? 'Edit' : 'ReadingMode',
                                        }}
                                        onClick={() => readOrEditDelivery(data.deliveryDataId)}
                                    />
                                </TooltipHost>
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    !item.isCreation && isJetFreeze ? (
                                        <TooltipHost
                                            // @ts-ignore
                                            content={tKey(deliveryTooltipText)}
                                            directionalHint={DirectionalHint.topAutoEdge}
                                            delay={TooltipDelay.long}
                                            styles={{ root: { margin: '-6px 0' } }}
                                        >
                                            <IconButton
                                                iconProps={{
                                                    iconName: deliveryIconName,
                                                }}
                                                styles={{
                                                    icon: {
                                                        color: deliveryIconColor,
                                                    },
                                                }}
                                                style={{ pointerEvents: 'none' }}
                                            />
                                        </TooltipHost>
                                    ) : item.isCreation && item.deliveryDatas?.length > 1 ? (
                                        <TooltipHost
                                            content={tKey('delete')}
                                            directionalHint={DirectionalHint.topCenter}
                                            delay={TooltipDelay.long}
                                        >
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                onClick={() => removeDelivery(data.deliveryDataId)}
                                                styles={{ root: { margin: '-6px 0' } }}
                                            />
                                        </TooltipHost>

                                    ) : (
                                        <IconButton
                                            disabled
                                            styles={{
                                                root: { color: 'transparent', backgroundColor: 'transparent' },
                                                rootDisabled: { margin: '-6px 0', color: 'transparent', backgroundColor: 'transparent' },
                                            }}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    )
                }

                if (data.orderHandlingId && !data.orderSubHandlingId) {
                    const support = param.supports.find(x => x.key === data.supportId)
                    return (
                        <span
                            onClick={() => {
                                setOrderHandlingId(prevSelectedContentId => (prevSelectedContentId !== data.orderHandlingId ? data.orderHandlingId : null))
                            }}
                            onKeyDown={() => null}
                            role="button"
                            tabIndex={0}
                            style={{
                                display: 'flex', gap: '0.5rem', cursor: 'pointer', margin: '-11px -11px -11px 2rem', padding: '11px',
                            }}
                        >
                            {isRaud && (
                                <Icon
                                    iconName={orderHandlingId !== data.orderHandlingId ? 'ChevronRight' : 'ChevronDown'}
                                />
                            )}
                            <table
                                className={classNames(
                                    styles['marchandise-support-data'],
                                    { [styles['is-raud']]: isRaud },
                                    { [styles['is-Pineau']]: isPineau },
                                    { [styles['is-jetfreeze']]: isJetFreeze },
                                )}
                            >
                                <tbody>
                                    {
                                        support.isWithoutReferences ? (
                                            <tr>
                                                <td>{support.text}</td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td>{tObj(parseJson(support?.text))}</td>

                                                <td>{' - '}</td>

                                                {(isRaud || isPineau) && (
                                                    <td>
                                                        {'Ref: '}
                                                        {data.reference}
                                                    </td>
                                                )}

                                                {isPineau && (
                                                    <td>
                                                        {'type: '}
                                                        todo
                                                    </td>
                                                )}

                                                {(isRaud || isPineau) && (
                                                    <td>{' - '}</td>
                                                )}

                                                {isJetFreeze && (
                                                    <td>
                                                        {tObj(parseJson(data.productTemperature.name))}
                                                    </td>
                                                )}

                                                <td>
                                                    {data.weight}
                                                    kg
                                                </td>

                                                {isJetFreeze && (
                                                    <td>
                                                        {`${tKey('quantity')}: ${data.quantity}`}
                                                    </td>
                                                )}
                                                {isRaud && (
                                                    <td>
                                                        {data.footage}
                                                        {tKey('linearMeterSuffix').toLocaleLowerCase()}
                                                    </td>
                                                )}
                                                {(isRaud || isPineau) && (
                                                    <td>
                                                        {data.long}
                                                        x
                                                        {data.width}
                                                        x
                                                        {data.height}
                                                        {' '}
                                                        cm
                                                    </td>
                                                )}
                                                <td>
                                                    <p>{data.comment}</p>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </span>
                    )
                }
                if (data.orderSubHandlingId)
                    return (
                        <span
                            className={styles['marchandise-article-data']}
                        >
                            <div>{`${data.quantity} ${data.article.name}`}</div>
                            <div>
                                -
                            </div>
                            <div>
                                {'Ref: '}
                                {data.reference}
                            </div>
                            <div>
                                -
                            </div>
                            <div>
                                {data.footage}
                                {tKey('linearMeterSuffix').toLocaleLowerCase()}
                            </div>
                            <div>
                                {data.weight}
                                kg
                            </div>
                            <div>
                                {data.long}
                                x
                                {data.width}
                                x
                                {data.height}
                                {' '}
                                cm
                            </div>
                            <div />
                        </span>
                    )
                return null
            },
        },
        // eslint-disable-next-line max-len
    ]), [tKey, deliveryDataId, userCanEdit, item.isCreation, item.deliveryDatas?.length, isJetFreeze, readOrEditDelivery, removeDelivery, param.supports, isRaud, orderHandlingId, isPineau, tObj])

    /** Item to be displayed */
    const items = useMemo(() => {
        const res = []

        // Get existing deliveries
        for (const [index, oneDeliveryData] of (deliveryDatas.filter(x => x.deliveryDataId)).entries()) { // eslint-disable-line no-restricted-syntax
            res.push({ ...oneDeliveryData, index: index + 1 })

            // Filter on selected delivery data id
            if (deliveryDataId === oneDeliveryData.deliveryDataId)
                for (const content of oneDeliveryData?.deliveryContentData?.orderHandlings ?? []) { // eslint-disable-line no-restricted-syntax
                    res.push(content)

                    // Filter on selected handling id
                    if (orderHandlingId === content.orderHandlingId)
                        for (const orderSubHandling of content.orderSubHandlings) // eslint-disable-line no-restricted-syntax
                            res.push(orderSubHandling)
                }
        }
        return res
    }, [deliveryDatas, orderHandlingId, deliveryDataId])

    useEffect(() => () => {
        setNewDeliveryDataHandler?.current?.cancel()
        removeDeliveryDataHandler?.current?.cancel()
    }, [])

    const {
        timeOrder, dayOfTheWeekId, name, supportContacts,
    } = me.company
    return (
        <>
            <div className={styles['confirmation-step']}>
                {
                    item.orderStatusId === EOrderStatus.Rejected && me.clientId && (
                        <Banner
                            text={tKey('orderRejectedBannerText',
                                {
                                    companyName: name.toUpperCase(),
                                    mail: (() => {
                                        const contact = me.client.supportContacts.find(x => x.supportContactTypeId === ESupportContact.Support)
                                        if (contact)
                                            return contact.email
                                        return supportContacts.find(x => x.supportContactTypeId === ESupportContact.Support)?.email
                                    })(),
                                })}
                            isRed
                        />
                    )
                }
                {
                    !isRaud && item.currentWorkflowStepId === EWorkflowStep.Creation && (
                        <Banner
                            text={(isJetFreeze) ? tKey('confirmationBannerTextJetfreeze', { timeOrder })
                                : tKey('confirmationBannerTextPineau', {
                                    timeOrder,
                                    dayOfTheWeek: tObj(parseJson(param.daysOfTheWeek.find(x => x.key === dayOfTheWeekId)?.text)).toLocaleLowerCase(),
                                })}
                        />
                    )
                }
                <Card
                    className={styles['confirmation-summary']}
                    title={tKey('summary')}
                    iconName="BulletedList2"
                    headerComponent={(
                        <Text>
                            {tObj(parseJson(item.orderStatus?.name))}
                        </Text>
                    )}
                >
                    <ShimmeredDetailsList
                        items={[item]}
                        columns={pickupColumns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                    />
                    <br />
                    <Divider
                        style={{
                            marginBottom: '0',
                        }}
                    />
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        enableUpdateAnimations
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderDetailsFooter={detailsFooterProps => (
                            <DetailsRow
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...detailsFooterProps}
                                columns={detailsFooterProps.columns.map(x => ({ ...x, onRender: null }))}
                                item={{ content: {} }}
                                itemIndex={-1}
                                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                                selectionMode={SelectionMode.none}
                                selection={detailsFooterProps.selection}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderItemColumn={(_, _index, column) => {
                                    switch (column.key) {
                                        case 'index':
                                            return (
                                                <span style={{ color: 'rgb(50, 49, 48)', fontSize: '13px', fontWeight: 600 }}>
                                                    {tKey('total')}
                                                </span>
                                            )
                                        case 'ref':
                                            return null
                                        case 'deliveryAddress':
                                            return (
                                                <span style={{ color: 'rgb(50, 49, 48)', fontSize: '13px', fontWeight: 600 }}>
                                                    {item.deliveryDatas.length ?? 0}
                                                    {' '}
                                                    {tKey('destinations')}
                                                </span>
                                            )
                                        case 'content':
                                            return (
                                                <span style={{ color: 'rgb(50, 49, 48)', fontSize: '13px', fontWeight: 600 }}>
                                                    {item.deliveryDatas
                                                        .map(x => x.deliveryContentData?.orderHandlings ?? [])
                                                        .flat().length ?? 0}
                                                    {' '}
                                                    {tKey('HU')}
                                                    {isRaud && (
                                                        <>
                                                            <span
                                                                style={{ marginLeft: '6rem' }}
                                                            />
                                                            {item.deliveryDatas
                                                                .map(x => x.deliveryContentData?.orderHandlings ?? [])
                                                                .flat()
                                                                .map(x => x.orderSubHandlings ?? [])
                                                                .flat().length ?? 0}
                                                            {' '}
                                                            {tKey('articles')}
                                                        </>
                                                    )}
                                                    {isJetFreeze && (
                                                        <>
                                                            <span
                                                                style={{ marginLeft: '6rem' }}
                                                            />
                                                            {tKey('totalWeight')}
                                                            {': '}
                                                            {item.deliveryDatas
                                                                .map(x => x.deliveryContentData?.orderHandlings ?? [])
                                                                .flat()
                                                                .reduce((acc, x) => x.weight + acc, 0)}
                                                            kg
                                                        </>
                                                    )}
                                                </span>
                                            )
                                        default:
                                            return null
                                    }
                                }}
                            />
                        )}
                    />
                    <br />
                    <div className={styles['confirmation-step-buttons']}>
                        <TooltipHost
                            content={tKey('printSummary')}
                            directionalHint={DirectionalHint.topCenter}
                            delay={TooltipDelay.zero}
                        >
                            <DefaultButton
                                text={tKey('editSummary')}
                                iconProps={{ iconName: 'Print' }}
                                onClick={() => printSummary()}
                                disabled={!item.isOrderPlacedInImagine || !item.canPrintLabels || summaryStatus === Status.PENDING}
                            />
                        </TooltipHost>
                        {summaryStatus === Status.PENDING ? (
                            <Spinner size={SpinnerSize.medium} />
                        ) : (<div style={{ width: '16px' }} />)}
                        <TooltipHost
                            // eslint-disable-next-line no-nested-ternary
                            content={tKey(!item.isOrderPlacedInImagine
                                ? 'waitingLabelsForValidation' : !item.canPrintLabels
                                    ? 'waitingLabelsForTransport' : 'printLabels')}
                            directionalHint={DirectionalHint.topCenter}
                            delay={TooltipDelay.long}
                        >
                            <DefaultButton
                                text={tKey('labels')}
                                iconProps={{ iconName: 'QRCode' }}
                                onClick={() => setIsLabelModalVisible(true)}
                                disabled={!item.isOrderPlacedInImagine || !item.canPrintLabels}
                            />
                        </TooltipHost>
                        {/* <DefaultButton // TODO: EN attente de la v2
                            text={tKey('trackingLink')}
                            iconProps={{ iconName: 'Mail' }}
                            onClick={e => {
                                e.preventDefault()
                                setIsTrackingLinkModalVisible(true)
                            }}
                            disabled={!item.isOrderPlacedInImagine}
                        /> */}
                        {[EOrderType.Delivery, EOrderType.DocksideShed].includes(item.orderTypeId) ? (
                            <PrimaryButton
                                text={tKey('addNewDelivery')}
                                disabled={!item.isCreation || !userCanEdit}
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => addNewDelivery()}
                            />
                        ) : (<div />)}
                    </div>
                </Card>
            </div>
            <TrackingLinkModal
                isVisible={isTrackingLinkModalVisible}
                setIsVisible={newVal => setIsTrackingLinkModalVisible(newVal)}
                lang={lang}
                ordersHandler={ordersHandler}
                data={{
                    orderId: item.orderId,
                }}
            />
            <LabelModal
                isVisible={isLabelModalVisible}
                setIsVisible={newVal => setIsLabelModalVisible(newVal)}
                lang={lang}
                ordersHandler={ordersHandler}
                data={{
                    orderId: item.orderId,
                }}
            />
        </>
    )
}
