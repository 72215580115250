import React, { PureComponent } from 'react'
import {
    DirectionalHint,
    getTheme, Icon, TextField, TooltipDelay, TooltipHost, VirtualizedComboBox,
} from '@fluentui/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnimationStyles } from '@fluentui/theme'

/** @debug {import('@fluentui/react').IComboBoxProps|import('@fluentui/react').ITextFieldProps} */

/**
 * @typedef {object} FilteredVirtualComboboxProps
 * @property {import('@fluentui/react').IComboBoxOption[]} options Options to show in combobox
 * @property {any} selectedKey Selected key(s) (Number or Number[])
 * @property {Function} onChange Is layout is displayed
 * @property {boolean=} readOnly Is in read only ?
 * @property {boolean=} isFilterAllowed Is filtered enable
 * @property {string?=} tooltipMessage tooltipMessage
 * @property {boolean=} isTooltipWarned isTooltipWarned
 * @augments {PureComponent<FilteredVirtualComboboxProps & import('@fluentui/react').ITextFieldProps & import('@fluentui/react').IComboBoxProps>}}
 */
export default class FilteredVirtualCombobox extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {import('@fluentui/react').IComboBoxOption[]} Options really displayed, based on this.props.options */
            optionsFiltered: [...props.options],
            /** @type {import('@fluentui/react').IComboBoxOption[]} Init options */
            optionsInit: [...props.options],
        }

        this.onRenderLabel = this.onRenderLabel.bind(this)
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { options } = this.props
        // Check if options have changed
        // eslint-disable-next-line curly
        if (JSON.stringify(prevProps.options) !== JSON.stringify(options)) {
            this.setState({
                optionsFiltered: [...options],
                optionsInit: [...options],
            })
        }
    }

    /**
     * Filter element display in list
     * @param {object} data data
     * @param {boolean=} data.isReset Is reseting content
     * @returns {void} void
     */
    onFilter({ isReset = false } = {}) {
        const { options, isFilterAllowed = true } = this.props

        const { optionsInit } = this.state

        if (this.worker)
            this.worker.terminate() // Stop worker if one is already running

        if (isReset) // Cleanup if reset
            return this.setState({ optionsFiltered: optionsInit })

        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        this.input._comboBox.current.focus(true) // Set focus on input

        if (!isFilterAllowed)
            return null

        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        const regExp = new RegExp(this.input._comboBox.current.state.currentPendingValue, 'gi') // Regex to find matching options

        this.worker = new Worker(
            this.fn2workerURL(
                `
                    function() {
                        var options = ${JSON.stringify(options)}
                        let optionsFiltered = options.filter(option => option.text.match(${regExp.toString()}) || option.data?.match(${regExp.toString()}))
                        postMessage(JSON.stringify(optionsFiltered))
                    }
                `,
            ),
        )

        this.worker.onmessage = ev => {
            this.setState({ optionsFiltered: JSON.parse(ev.data) }, () => this.worker.terminate())
        }

        return null
    }

    onRenderLabel(renderProps, defaultRender) {
        const { tooltipMessage, isTooltipWarned } = this.props

        if (!tooltipMessage)
            return defaultRender(renderProps)
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {defaultRender(renderProps)}
                <div
                    style={{
                        marginLeft: '0,5rem', marginTop: '3px', fontSize: '16px', cursor: 'default',
                    }}
                >
                    <TooltipHost
                        content={tooltipMessage}
                        directionalHint={DirectionalHint.topCenter}
                        delay={TooltipDelay.zero}
                    >
                        <Icon
                            iconName="Info"
                            style={isTooltipWarned ? { color: '#e90000' } : {}}
                        />
                    </TooltipHost>
                </div>
            </div>
        )
    }

    /**
     * Create a blob object from fonction stringify
     * @param {string} fn fn
     * @returns {string} string
     */
    // eslint-disable-next-line class-methods-use-this
    fn2workerURL(fn) {
        const blob = new Blob([`(${fn})()`], { type: 'application/javascript' })
        return URL.createObjectURL(blob)
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const { readOnly = false, selectedKey, options } = this.props
        const { optionsFiltered } = this.state

        if (readOnly) {
            const propsClean = { ...this.props }
            delete propsClean.borderless
            delete propsClean.readOnly
            delete propsClean.defaultValue

            return (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...propsClean}
                    borderless
                    readOnly
                    value={propsClean.defaultValue
                        ?? (
                            !Array.isArray(selectedKey)
                                ? options?.find(x => x.key === propsClean?.selectedKey)?.text
                                : options?.filter(x => propsClean?.selectedKey?.includes(/** @type {never} */(x.key))).map(x => x.text).join(', ')
                        )
                        ?? ''}
                    onChange={() => null}
                />
            )
        }
        const propsClean = { ...this.props }
        delete propsClean.allowFreeform
        delete propsClean.autoComplete
        delete propsClean.options
        delete propsClean.useComboBoxAsMenuWidth
        delete propsClean.onItemClick

        return (
            <div
                style={{
                    position: 'relative',
                    // paddingBottom: !!this.props.errorMessage ? "15px" : undefined
                }}
            >
                <VirtualizedComboBox
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {.../** @type {any} */(propsClean)}
                    allowFreeform
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    options={optionsFiltered}
                    componentRef={input => { this.input = input }}
                    onKeyUp={ev => {
                        if (![38, 40, 13].includes(ev.keyCode))
                            this.onFilter() // If not Up, Down, Enter
                        if (propsClean.onKeyUp)
                            propsClean.onKeyUp(ev) // If provided
                    }}
                    onMenuOpen={() => {
                        this.onFilter()
                        if (propsClean.onMenuOpen)
                            propsClean.onMenuOpen() // If provided
                    }}
                    onChange={(event, option, index, value) => {
                        if (
                            propsClean.onChange // If provided
                            && event.type !== 'blur' // If not bluring input
                            && option // If option is defined
                        )
                            propsClean.onChange(event, option, index, value)
                        if (propsClean.onChange
                            && !propsClean.multiSelect
                            // @ts-ignore
                            && typeof event.code === 'string' && event.code === 'Enter'
                            && optionsFiltered.length === 1
                        )
                            propsClean.onChange(event, optionsFiltered[0], 0, optionsFiltered[0].text)
                    }}
                    onItemClick={(event, option, index) => {
                        if (
                            propsClean.onChange // If provided
                            && option // If option is defined
                        ) {
                            // eslint-disable-next-line no-nested-ternary
                            const opt = !Array.isArray(propsClean.selectedKey)
                                ? (option.key !== propsClean.selectedKey ? option : { key: null, text: null })
                                : option
                            propsClean.onChange(event, opt, index)
                        }
                    }}
                    onMenuDismissed={() => {
                        this.onFilter({ isReset: true })
                        if (propsClean.onMenuDismissed)
                            propsClean.onMenuDismissed() // If provided
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderOption={option => (propsClean.onRenderOption
                        ? propsClean.onRenderOption(option) : (
                            <span
                                style={{
                                    color: propsClean.selectedKey === option.key ? getTheme().palette.themeSecondary : null,
                                    fontWeight: propsClean.selectedKey === option.key ? 'bold' : null,
                                }}
                            >
                                {option.data ? (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ marginTop: '0.25rem' }}>{option.text?.toString()?.trim() || <>&nbsp;</>}</div>
                                        <div style={{ fontSize: '0.75rem', color: '#666' }}>{option.data.toString()?.trim()}</div>
                                    </div>
                                ) : (
                                    <div>{option.text?.toString()?.trim() || <>&nbsp;</>}</div>
                                )}

                            </span>
                        ))}
                    styles={{
                        ...propsClean.styles,
                        errorMessage: {
                            // @ts-ignore
                            ...(propsClean.styles?.errorMessage ?? {}),
                            ...AnimationStyles.slideDownIn20,
                        },
                    }}
                    onRenderLabel={this.onRenderLabel}
                />
                {propsClean.required && !propsClean.disabled
                    && (
                        <TextField
                            value={selectedKey?.toString() || ''}
                            onChange={() => null}
                            styles={{
                                wrapper: {
                                    opacity: 0,
                                },
                                root: {
                                    position: 'absolute',
                                    bottom: 0,
                                    pointerEvents: 'none',
                                },
                            }}
                            tabIndex={-1}
                            required
                        />
                    )}
            </div>
        )
    }
}
