import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import OperatingCenter, { ErrorOperatingCenter } from 'requests/objects/operatingCenter'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import usePrevious from 'helpers/hooks/usePrevious'
import PhoneField from 'components/inputs/phoneField'
import { Columns } from 'react-bulma-components'
import styles from 'styles/components/pages/companies/operating-centers/operating-center-modal.module.scss'
import EFileFolder from 'types/files/enums/fileFolder'
import FilesHandler from 'requests/handlers/filesHandler'
import PreviewFileInput from 'components/pages/home-articles/[id]/previewFileInput'
import FileInput from 'components/inputs/fileInput'
import File from 'requests/objects/file'

/**
 * operatingCenter modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {Partial<OperatingCenter>} props.operatingCenterData operatingCenterData must send operatingCenterId prop at creation
 * @param {(operatingCenter: OperatingCenter) => void} props.onChange Callback to complete the operatingCenter form
 * @param {string} props.lang lang
 * @returns {JSX.Element} operatingCenter modal form to create/update a operatingCenter
 */
export default function OperatingCenterModal({
    isVisible,
    companiesHandler,
    filesHandler,
    operatingCenterData,
    onChange,
    lang,
}) {
    const [operatingCenter, setOperatingCenter] = useState(new OperatingCenter(operatingCenterData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [tempLogo, setTempLogo] = useState(null)
    const [errorField, setErrorField] = useState(new ErrorOperatingCenter())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<OperatingCenter>>} */
    const upsertOperatingCenterHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerUploadFile = useRef()

    const prevIsVisible = usePrevious(isVisible)

    // Set logistic constraint data for edition or creation. Ensure that data in modal are up to date
    useEffect(() => {
        if (isVisible && prevIsVisible !== isVisible) {
            setErrorMessage(null)
            setErrorField(new ErrorOperatingCenter())
            setOperatingCenter(new OperatingCenter(operatingCenterData))
        }
    }, [operatingCenterData, isVisible, prevIsVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertOperatingCenterHandler.current = companiesHandler.upsertOperatingCenter(
                operatingCenter.companyId, operatingCenter, operatingCenter.operatingCenterId,
            )
            const operatingCenterResult = await upsertOperatingCenterHandler.current.fetch()
            if (tempLogo) {
                handlerUploadFile.current = filesHandler.uploadFile(
                    operatingCenterResult?.operatingCenterId,
                    EFileFolder.OperatingCenterLogo,
                    tempLogo,
                )
                operatingCenterResult.logo = await handlerUploadFile.current.fetch()
                operatingCenterResult.logoId = operatingCenterResult.logo.fileId
            }
            onChange(operatingCenterResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorOperatingCenter>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [companiesHandler, operatingCenter, tempLogo, onChange, filesHandler])

    // On component did unmount
    useEffect(() => () => {
        upsertOperatingCenterHandler.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: operatingCenter?.operatingCenterId ? tKey('modalEditOperatingCenterTitle') : tKey('modalOperatingCenterTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="800px"
                minWidth="444px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                />
                <Columns className={styles['operating-center-modal']}>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('name')}
                            placeholder={tKey('name')}
                            value={operatingCenter.name || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, name: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.name)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('abbreviation')}
                            placeholder={tKey('abbreviation')}
                            value={operatingCenter.shortName || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, shortName: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.shortName)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('tmsCode')}
                            placeholder={tKey('tmsCode')}
                            value={operatingCenter.operatingCenterTmsId || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, operatingCenterTmsId: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.operatingCenterTmsId)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('code')}
                            placeholder={tKey('code')}
                            value={operatingCenter.code || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, code: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.code)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('email')}
                            placeholder={tKey('email')}
                            value={operatingCenter.email || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, email: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.email)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <PhoneField
                            label={tKey('phoneNumber')}
                            placeholder={tKey('phoneNumber')}
                            value={operatingCenter.phone}
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, phone: newVal }))}
                            required
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.phone)}
                            type="tel"
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('street')}
                            placeholder={tKey('street')}
                            value={operatingCenter.street || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, street: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.street)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label={tKey('city')}
                            placeholder={tKey('city')}
                            value={operatingCenter.city || ''}
                            required
                            onChange={(ev, newVal) => setOperatingCenter(prevState => ({ ...prevState, city: newVal }))}
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.city)}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        {operatingCenter.operatingCenterId
                            ? (
                                <PreviewFileInput
                                    label={tKey('logo')}
                                    handler={filesHandler}
                                    fileFolderId={EFileFolder.OperatingCenterLogo}
                                    entityId={operatingCenter.operatingCenterId}
                                    isRequired={false}
                                    isDisabled={status === Status.PENDING}
                                    isReadOnly={false}
                                    file={operatingCenter.logo}
                                    updateItem={file => setOperatingCenter(prevState => ({ ...prevState, logo: file }))}
                                />
                            )
                            : (
                                <FileInput
                                    label={tKey('logo')}
                                    isReadOnly={false}
                                    fileName={tempLogo?.name}
                                    tooltipContent=""
                                    onDownload={() => null}
                                    onUpload={file => Promise.resolve(setTempLogo(file))}
                                    onDelete={() => Promise.resolve(setTempLogo(null))}
                                />
                            )}
                    </Columns.Column>
                </Columns>
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
