/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import File from 'requests/objects/file'

/**
 * OperatingCenter Object
 */
export default class OperatingCenter extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {string=} data.operatingCenterTmsId operatingCenterTmsId
     * @param {string=} data.code code
     * @param {number=} data.companyId companyId
     * @param {string=} data.name name
     * @param {string=} data.phone phone
     * @param {string=} data.shortName shortName
     * @param {string=} data.email email
     * @param {string=} data.street street
     * @param {string=} data.city city
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.logoId logoId
     * @param {object=} data.logo logo
     */
    constructor({
        operatingCenterId,
        operatingCenterTmsId,
        code,
        companyId,
        name,
        phone,
        shortName,
        email,
        street,
        city,
        zipCode,
        logoId,
        logo,
        ...data
    } = {}) {
        super(data)
        this.operatingCenterId = operatingCenterId
        this.operatingCenterTmsId = operatingCenterTmsId
        this.code = code
        this.companyId = companyId
        this.name = name
        this.phone = phone
        this.shortName = shortName
        this.email = email
        this.street = street
        this.city = city
        this.zipCode = zipCode
        this.logoId = logoId
        this.logo = logo ? new File(logo) : undefined
    }
}

/**
 * OperatingCenter Object used to bind error message
 */
export class ErrorOperatingCenter extends ErrorBase {
    constructor() {
        super()
        this.operatingCenterId = ''
        this.operatingCenterTmsId = ''
        this.code = ''
        this.companyId = ''
        this.name = ''
        this.phone = ''
        this.shortName = ''
        this.email = ''
        this.street = ''
        this.city = ''
        this.zipCode = ''
        this.logoId = ''
    }
}
