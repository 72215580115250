import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import Loader from 'components/visuals/loader'
import useAddressModalMap from 'helpers/hooks/components/pages/useAddressModalMap'
import styles from 'styles/components/pages/address-modal-map.module.scss'
import {
    IconButton, PrimaryButton, Slider, Text, TextField,
} from '@fluentui/react'

/**
 * AddressModal
 * @param {object} props props
 * @param {{lat: number; lng: number}=} props.mapMarker classicAddressMarker
 * @param {boolean=} props.isEnable isEnable
 * @param {({lat, lon}: {lat: string; lon: string }) => void} props.onMapMarkerUpdate onMapMarkerUpdate
 * @param {(newVal: boolean) => void=} props.setIsEnable setIsEnable
 * @param {string} props.lang Lang
 * @returns {JSX.Element} Map
 */
export default function AddressModalMap({
    mapMarker = { lat: null, lng: null },
    isEnable,
    setIsEnable,
    onMapMarkerUpdate,
    lang,
}) {
    const {
        isLoaded, options, marker, setMarker, tKey, onLoad, onUnmount, zoom, onZoomChanged, setZoom, center,
    } = useAddressModalMap({ isEnable, mapMarker, lang })

    if (!isLoaded)
        return <Loader />

    return (
        <div className={styles['address-modal-map']}>
            <GoogleMap
                mapContainerStyle={{
                    minHeight: '450px',
                    height: '100%',
                }}
                options={options}
                onClick={ev => {
                    if (isEnable) {
                        setMarker({ lat: ev.latLng.lat(), lng: ev.latLng.lng() })
                        onMapMarkerUpdate({ lat: ev.latLng.lat()?.toString(), lon: ev.latLng.lng()?.toString() })
                    }
                }}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={zoom}
                onZoomChanged={onZoomChanged}
            >
                {marker.lat && marker.lng && (
                    <Marker
                        position={marker}
                        icon={isEnable ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                        draggable={isEnable}
                        onDrag={ev => {
                            setMarker({ lat: ev.latLng.lat(), lng: ev.latLng.lng() })
                            onMapMarkerUpdate({ lat: ev.latLng.lat()?.toString(), lon: ev.latLng.lng()?.toString() })
                        }}
                        title={tKey('address')}
                    />
                )}
            </GoogleMap>
            <div className={styles['address-modal-map-shadow']} />
            {!isEnable && (
                <div className={styles['address-modal-map-start']}>
                    <Text variant="smallPlus">
                        {tKey('ifYourAddressIsNotListed')}
                    </Text>
                    <br />
                    <br />
                    <PrimaryButton
                        text={tKey('placeCursorManually')}
                        onClick={() => setIsEnable(true)}
                    />
                </div>
            )}
            {isEnable && (
                <div className={styles['address-modal-map-infos']}>
                    <Text variant="smallPlus">
                        {tKey('moveTheCursor')}
                    </Text>
                    <div className={styles['address-modal-map-infos-columns']}>
                        <div>
                            <TextField
                                label={tKey('latitude')}
                                placeholder={tKey('latitude')}
                                value={marker.lat?.toString() || ''}
                                onChange={(ev, newVal) => {
                                    setMarker(prevVal => ({ ...prevVal, lat: +newVal }))
                                    onMapMarkerUpdate({ lat: newVal, lon: mapMarker.lng?.toString() })
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                label={tKey('longitude')}
                                placeholder={tKey('longitude')}
                                value={marker.lng?.toString() || ''}
                                onChange={(ev, newVal) => {
                                    setMarker(prevVal => ({ ...prevVal, lng: +newVal }))
                                    onMapMarkerUpdate({ lat: mapMarker.lat?.toString(), lon: newVal })
                                }}
                            />
                        </div>
                        <div>
                            <IconButton
                                iconProps={{ iconName: 'Cancel' }}
                                title={tKey('cancel')}
                                onClick={() => {
                                    setIsEnable(false)
                                    onMapMarkerUpdate({ lat: null, lon: null })
                                    // setMarker(mapMarker)
                                    // onMapMarkerUpdate({ lat: mapMarker.lat.toString(), lon: mapMarker.lng.toString() })
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles['address-modal-slider']}>
                <Slider
                    styles={{ container: { height: '300px' } }}
                    min={2}
                    max={20}
                    step={1}
                    value={zoom}
                    showValue={false}
                    onChange={val => setZoom(val)}
                    vertical
                />
            </div>
        </div>
    )
}
