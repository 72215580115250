// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'
import File from 'requests/objects/file'
import Profile from 'requests/objects/profile'
import FileCategory from 'requests/objects/fileCategory'
import CompanyRule from 'requests/objects/companyRule'
import SupportContact from 'requests/objects/supportContact'
import LogisticConstraint from 'requests/objects/logisticConstraint'
import Address from 'requests/objects/address'
import DeliveryType from 'requests/objects/deliveryType'
import Accessibility from 'requests/objects/accessibility'

/**
 * Company Object
 */
export default class Company extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.companyId companyId
     * @param {string=} data.name name
     * @param {string=} data.exportFileFormat exportFileFormat
     * @param {string=} data.exportFileVersion exportFileVersion
     * @param {boolean=} data.isValidateExploitationRequired isValidateExploitationRequired
     * @param {boolean=} data.isOperational isOperational
     * @param {string=} data.timeOrder timeOrder
     * @param {number=} data.dayOfTheWeekId dayOfTheWeekId
     * @param {number=} data.logoId logoId
     * @param {object=} data.logo logo
     * @param {object[]=} data.roles roles
     * @param {object[]=} data.fileCategories fileCategories
     * @param {object=} data.companyRule companyRule
     * @param {object[]=} data.supportContacts supportContacts
     * @param {object[]=} data.logisticConstraints logisticConstraints
     * @param {object[]=} data.deliveryTypes deliveryTypes
     * @param {object[]=} data.accessibilities accessibilities
     * @param {object[]=} data.addresses addresses
     */
    constructor({
        companyId = 0,
        name = '',
        exportFileFormat = '',
        exportFileVersion = '1.0.0',
        isValidateExploitationRequired = false,
        isOperational = true,
        timeOrder = '18:00',
        dayOfTheWeekId = 0,
        logoId = null,
        logo,
        roles = [],
        fileCategories = [],
        companyRule = {},
        supportContacts = [],
        logisticConstraints = [],
        addresses = [],
        deliveryTypes = [],
        accessibilities = [],
        ...data
    } = {}) {
        super(data)
        this.companyId = companyId
        this.name = name
        this.exportFileFormat = exportFileFormat
        this.exportFileVersion = exportFileVersion
        this.isValidateExploitationRequired = isValidateExploitationRequired
        this.isOperational = isOperational
        this.timeOrder = timeOrder
        this.dayOfTheWeekId = dayOfTheWeekId
        this.logoId = logoId
        this.logo = logo ? new File(logo) : undefined
        this.roles = roles?.map(x => new Profile(x)) ?? []
        this.roleIds = this.roles.map(x => x.profileId)
        this.fileCategories = fileCategories?.map(x => new FileCategory(x)) ?? []
        this.companyRule = new CompanyRule(companyRule)
        this.supportContacts = supportContacts?.map(x => new SupportContact(x)) ?? []
        this.logisticConstraints = logisticConstraints?.map(x => new LogisticConstraint(x)) ?? []
        this.deliveryTypes = deliveryTypes?.map(x => new DeliveryType(x)) ?? []
        this.accessibilities = accessibilities?.map(x => new Accessibility(x)) ?? []
        this.addresses = addresses?.map(x => new Address(x)) ?? []
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorCompany extends ErrorBase {
    constructor() {
        super()
        this.companyId = ''
        this.name = ''
        this.exportFileFormat = ''
        this.exportFileVersion = ''
        this.isValidateExploitationRequired = ''
        this.timeOrder = ''
        this.logoId = ''
        this.logo = ''
        this.roleIds = ''
        this.intermediateWorkflowStepIds = ''
        this.companyRule = {
            areAddressTimeSlotsMandatory: '',
            companyRuleWorkflowStepIds: '',
        }
        this.dayOfTheWeekId = ''
    }
}
