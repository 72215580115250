import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import AddressesHandler from 'requests/handlers/addressesHandler'
import Contact, { ErrorContact } from 'requests/objects/contact'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import PhoneField from 'components/inputs/phoneField'
import parseJson from 'helpers/methods/parseJson'
import ECompany from 'types/companies/enums/company'

/**
 * Contact modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {number} props.companyId companyId
 * @param {AddressesHandler} props.handler handler
 * @param {Partial<Contact>} [props.contactData] contactData must send addressId prop at creation
 * @param {(contact: Contact) => void} props.onChange Callback to complete the contact form
 * @param {string} props.lang lang
 * @param {boolean} [props.isReadOnly] isReadOnly
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function ContactModal({
    isVisible,
    companyId,
    handler,
    contactData,
    onChange,
    lang,
    isReadOnly = false,
}) {
    const [contact, setContact] = useState(new Contact(contactData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorContact())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<Contact>>} */
    const upsertContactHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<Contact>>} */
    const getContactHandler = useRef()

    useEffect(() => {
        if (isVisible && ((contactData && contactData.contactId !== contact?.contactId) || contactData.addressId !== contact.addressId))
            setContact(new Contact(contactData))
    }, [setContact, contact, contactData, isVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertContactHandler.current = handler.upsertContact(contact.addressId, contact)
            const contactResult = await upsertContactHandler.current.fetch()
            onChange(contactResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorContact>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [handler, contact, onChange, setStatus])

    // On component did unmount
    useEffect(() => () => {
        upsertContactHandler.current?.cancel()
        getContactHandler.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: contact?.contactId ? tKey('modalEditContactTitle') : tKey('modalContactTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                />
                <TextField
                    label={tKey('firstname')}
                    placeholder={tKey('firstname')}
                    value={contact.firstname}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, firstname: newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(parseJson(errorField.firstname))}
                />
                <TextField
                    label={tKey('lastname')}
                    placeholder={tKey('lastname')}
                    value={contact.lastname}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, lastname: newVal }))}
                    required
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(parseJson(errorField.lastname))}
                />
                <TextField
                    label={tKey('email')}
                    placeholder={tKey('email')}
                    value={contact.email}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, email: newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(parseJson(errorField.email))}
                />
                <PhoneField
                    label={tKey('phoneNumber')}
                    placeholder={tKey('phoneNumber')}
                    value={contact.phoneNumber}
                    readOnly={isReadOnly}
                    required={companyId === ECompany.JetFreeze}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, phoneNumber: newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(parseJson(errorField.phoneNumber))}
                    type="tel"
                />
                <PhoneField
                    label={tKey('phoneNumber2')}
                    placeholder={tKey('phoneNumber2')}
                    value={contact.phoneNumber2}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, phoneNumber2: newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(parseJson(errorField.phoneNumber2))}
                    type="tel"
                />
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                        disabled={
                            status === Status.PENDING || !contact.lastname || (companyId === ECompany.JetFreeze && !contact.phoneNumber)
                        }
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
