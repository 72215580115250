import { getTheme } from '@fluentui/react'
import React from 'react'
import styles from 'styles/components/visuals/loader.module.scss'

/**
 * Loader component
 * @param {object} props props
 * @param {object=} props.color Color of loader
 * @returns {JSX.Element} Element
 */
function Loader({ color = getTheme().palette.themePrimary }) {
    return (
        <div className={styles.loader}>
            <div>
                <div style={{ borderColor: color }} />
                <div style={{ borderColor: color }} />
            </div>
        </div>
    )
}

export default Loader
